import { DIContainer } from 'eight.js.store-common';
import { AnalyticsService } from './store/analytics/service';
import { BrandService } from './store/brand/service';
import { CampaignService } from './store/campaign/campaign/service';
import { SponsorshipSetService } from './store/campaign/sponsorshipSet/service';
import { SponsorshipService } from './store/campaign/sponsorship/service';
import { ManageService } from './store/manage/service';
import { CampaignDataService } from './store/data/service';
import { FetchService } from './services/fetch/service';
import { BasicAdSponsorshipSetService } from './store/basicAd/sponsorshipSet/service';
import { BasicAdSponsorshipService } from './store/basicAd/sponsorship/service';
import { BasicAdService } from './store/basicAd/campaign/service';
import { BasicAdCampaignDataService } from './store/basicAdData/service';
import { ManageBasicAdsService } from './store/manageBasicAds/service';
import { BasicAdAnalyticsService } from './store/analytics/basicAds/service';

export const container = new DIContainer();

container.singleton('FetchService', FetchService);
container.singleton('AnalyticsService', AnalyticsService, ['FetchService']);
container.singleton('BrandService', BrandService, ['FetchService']);
container.singleton('CampaignService', CampaignService, ['FetchService']);
container.singleton('SponsorshipSetService', SponsorshipSetService, ['FetchService']);
container.singleton('SponsorshipService', SponsorshipService, ['FetchService']);
container.singleton('ManageService', ManageService, ['FetchService']);
container.singleton('CampaignDataService', CampaignDataService, ['FetchService']);

container.singleton('ManageBasicAdsService', ManageBasicAdsService, ['FetchService']);
container.singleton('BasicAdService', BasicAdService, ['FetchService']);
container.singleton('BasicAdSponsorshipSetService', BasicAdSponsorshipSetService, ['FetchService']);
container.singleton('BasicAdSponsorshipService', BasicAdSponsorshipService, ['FetchService']);
container.singleton('BasicAdCampaignDataService', BasicAdCampaignDataService, ['FetchService']);
container.singleton('BasicAdAnalyticsService', BasicAdAnalyticsService, ['FetchService']);
