import { t } from '@transifex/native';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';

const page = { height: 470, width: 665 };
const wholePageSize = { height: 595, width: 842 };

const calculateDimensions = (canvas) => {
  let imageWidth = canvas.width;
  let imageHeight = canvas.height;

  const canvasRatio = imageWidth / imageHeight;
  const pageRatio = page.width / page.height;

  if (canvasRatio >= pageRatio) {
    imageHeight = (page.width * canvas.height) / canvas.width;
    imageWidth = page.width;
  } else {
    imageHeight = page.height;
    imageWidth = (page.height * canvas.width) / canvas.height;
  }

  const marginX = (wholePageSize.width - imageWidth) / 2;
  const marginY = (wholePageSize.height - imageHeight) / 2;

  return { imageWidth, imageHeight, marginX, marginY };
};

export const generateCampaignPerformancePdf = (
  heroNode,
  overviewNode,
  responsesNode,
  demographicsNode,
  setIsPdfProcessing,
  name,
  type,
  tab,
  isBasicAd
) => {
  toast.info(t('Generating PDF file. Please wait...'));

  setIsPdfProcessing(true);

  var options = { orientation: 'l', unit: 'px', format: [595, 842] };
  var doc = new jsPDF(options);
  doc.setFontSize(30);

  // Title
  doc.text(name, wholePageSize.width / 2, 60, { align: 'center' });

  // There are nested functions for synchronization purposes
  html2canvas(heroNode, {
    scale: 2,
  })
    .then(function (canvas) {
      var canvasImg = canvas.toDataURL('image/jpg');

      const { imageWidth, imageHeight, marginX } = calculateDimensions(canvas);

      doc.addImage(canvasImg, 'PNG', marginX, 90, imageWidth, imageHeight, undefined, 'FAST');
      doc.addPage();
      if (tab === 'demographics') {
        html2canvas(demographicsNode[0], {
          scale: 2,
        }).then(function (canvas) {
          var canvasImg = canvas.toDataURL('image/jpg');
          const { imageWidth, imageHeight, marginX } = calculateDimensions(canvas);

          doc.text('Demographics', wholePageSize.width / 2, 60, { align: 'center' });
          doc.addImage(canvasImg, 'PNG', marginX, 90, imageWidth, imageHeight, undefined, 'FAST');
          doc.addPage();

          html2canvas(demographicsNode[1], {
            scale: 2,
          }).then(function (canvas) {
            var canvasImg = canvas.toDataURL('image/jpg');
            const { imageWidth, imageHeight, marginX } = calculateDimensions(canvas);

            doc.addImage(canvasImg, 'PNG', marginX, 90, imageWidth, imageHeight, undefined, 'FAST');

            doc.addPage();
            html2canvas(demographicsNode[2], {
              scale: 2,
            }).then(function (canvas) {
              var canvasImg = canvas.toDataURL('image/jpg');
              const { imageWidth, imageHeight, marginX } = calculateDimensions(canvas);

              doc.addImage(
                canvasImg,
                'PNG',
                marginX,
                90,
                imageWidth,
                imageHeight,
                undefined,
                'FAST'
              );

              toast.success(t('PDF file generated successfully.'));
              doc.save(name + ' - Performance Overview.pdf');
            });
          });
        });
      } else {
        html2canvas(overviewNode, {
          scale: 2,
        }).then(function (canvas) {
          var canvasImg = canvas.toDataURL('image/jpg');
          const { imageWidth, imageHeight, marginX } = calculateDimensions(canvas);

          doc.text('Overview', wholePageSize.width / 2, 60, { align: 'center' });
          doc.addImage(canvasImg, 'PNG', marginX, 90, imageWidth, imageHeight, undefined, 'FAST');

          if (type !== 'ad') {
            toast.success(t('PDF file generated successfully.'));
            doc.save(name + ' - Performance Overview.pdf');
          } else {
            doc.addPage();

            html2canvas(responsesNode, {
              scale: 2,
            }).then(function (canvas) {
              var canvasImg = canvas.toDataURL('image/jpg');
              const { imageWidth, imageHeight, marginX } = calculateDimensions(canvas);
              isBasicAd && doc.text('Responses', wholePageSize.width / 2, 60, { align: 'center' });
              doc.addImage(
                canvasImg,
                'PNG',
                marginX,
                90,
                imageWidth,
                imageHeight,
                undefined,
                'FAST'
              );
              toast.success(t('PDF file generated successfully.'));
              doc.save(name + ' - Performance Overview.pdf');
            });
          }
        });
      }
      setIsPdfProcessing(false);
    })
    .catch(() => {
      toast.error(t('There was an error generating PDF file'));
    });
};
