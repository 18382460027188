import config from '../../config';
import { basicAdConstants } from '../../constants';
import sponsorshipConstants from '../../constants/basicAd/sponsorship-data';
import sponsorshipSetConstants from '../../constants/basicAd/sponsorshipSet-data';
import { formatToBrandsTimezone } from '../../utils/date/dateHelpers';
import {
  processAge,
  processCharity,
  processDifferences,
  processGender,
  processInterests,
  processLocations,
  processMediaType,
  processOs,
  processPassions,
  processQuestions,
  processReceivedText,
  processVodStatus,
} from '../../utils/receivedData';

export class BasicAdCampaignDataService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  getCompleteCampaign = async ({ campaignId, utcString }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v2/basic-ads/campaigns/${campaignId}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    const {
      adSets,
      campaignBrand,
      charity,
      deliveryStatus,
      id,
      isActive,
      isArchived,
      name,
      objective,
    } = response.data;

    const responseAdSetsData = adSets;

    let formattedAdSetsData = [sponsorshipSetConstants.initialState];
    if (responseAdSetsData.length)
      formattedAdSetsData = responseAdSetsData.map((resAdSet) => {
        const {
          ads,
          ageRanges,
          dailyBudget,
          deliveryStatus,
          deviceType,
          eightPixelAudiences,
          endDate,
          frequencyCap,
          genders,
          id,
          includeLocations,
          interests,
          isActive,
          isEightPixelEnabled,
          name,
          passions,
          pixelConversionEvent,
          startDate,
          totalBudget,
          unpublishedChanges,
        } = resAdSet;

        const resAds = ads;

        let formattedAds = [sponsorshipConstants.initialState];
        if (resAds.length)
          formattedAds = resAds.map((ad) => {
            const {
              cta,
              deliveryStatus,
              id,
              isActive,
              caption,
              mediaDetails,
              mediaType,
              name,
              vodStatus,
              unpublishedChanges,
            } = ad;
            return {
              id,
              isActive,
              isDraft: deliveryStatus === 'draft',
              name,
              mediaType: processMediaType(mediaType),
              deliveryStatus: deliveryStatus ? processReceivedText(deliveryStatus) : 'inactive',
              adVideo: mediaDetails.uri || '',
              ctaButtonText: cta?.buttonText || '',
              ctaLink: cta?.url || '',
              videoUploadStatus: vodStatus ? processVodStatus(vodStatus) : '',
              isCreated: true,
              caption,
              unpublishedChanges,
              // thirdPartyTrackingScripts:
              //   cta?.adScript && thirdPartyTrackingScripts
              //     ? [
              //         { provider: 'doubleClick', script: cta?.adScript },
              //         ...thirdPartyTrackingScripts,
              //       ]
              //     : !cta?.adScript
              //     ? thirdPartyTrackingScripts
              //     : [{ provider: 'doubleClick', script: cta?.adScript }],
            };
          });

        return {
          id,
          isActive,
          isDraft: deliveryStatus === 'draft',
          isEightPixelEnabled,
          pixelConversionEvent,
          name,
          deliveryStatus: deliveryStatus ? processReceivedText(deliveryStatus) : 'inactive',
          age: ageRanges ? processAge(ageRanges) : sponsorshipSetConstants.defaultAgeData,
          gender: genders ? processGender(genders) : sponsorshipSetConstants.defaultGenderData,
          deviceType: deviceType ? processOs(deviceType) : sponsorshipSetConstants.defaultOsData,
          budget: totalBudget || '',
          dailyBudget: dailyBudget || '',
          startDate: startDate ? formatToBrandsTimezone(startDate, utcString) : '',
          endDate: endDate ? formatToBrandsTimezone(endDate, utcString) : '',
          interests: interests ? processInterests(interests) : [],
          passions: passions ? processPassions(passions) : [],
          frequencyCap: frequencyCap || '',
          locations: includeLocations ? processLocations(includeLocations) : [],
          eightPixelAudiences: eightPixelAudiences ?? [],
          pricePerMessage: '',
          isCreated: true,
          selectedCharity: charity
            ? processCharity(charity.id, charity.name, charity.logoUri)
            : { value: '', label: '' },
          charities: charity ? [processCharity(charity.id, charity.name, charity.logoUri)] : [],
          ads: formattedAds,
          unpublishedChanges,
        };
      });

    return {
      id,
      name,
      campaignBrandName: campaignBrand.name ?? '',
      campaignBrandLogoUri: campaignBrand.logoUri || '',
      category: objective || basicAdConstants.objectives[0].value,
      isActive,
      isArchive: isArchived,
      isDraft: deliveryStatus === 'draft',
      isCreated: true,
      deliveryStatus: deliveryStatus ? processReceivedText(deliveryStatus) : 'inactive',
      selectedCharity: charity ? processCharity(charity.id, charity.name, charity.logoUri) : '',
      charityCategory: charity ? processCharity(charity.category, charity.category, '') : '',
      charities: charity ? [processCharity(charity.id, charity.name, charity.logoUri)] : [],
      adSets: formattedAdSetsData,
    };
  };

  getDifferences = async ({ campaignId }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/basic-ads/${campaignId}/unpublished-differences`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }
    const data = response.data;

    return processDifferences(data);
  };

  deleteAdSet = async ({ campaignId, adSetId }) => {
    const response = await this.fetchService.fetch({
      method: 'DELETE',
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-set/${adSetId}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }
  };

  deleteAd = async ({ campaignId, adSetId, adId }) => {
    const response = await this.fetchService.fetch({
      method: 'DELETE',
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-set/${adSetId}/ad/${adId}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }
  };
}
