import config from '../../../config';
import axios from 'axios';
import { store } from '../..';
import { setBasicAdUploadPercentage } from './actions';
import { getDimensions } from '../../../utils/media/media-utils';

export class BasicAdSponsorshipService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  updateBasicAdPercentage = (percentage, cancel) => {
    const state = store.getState();
    if (state.basicAdSponsorship.sponsorshipCreate.uploadingVideo) {
      store.dispatch(setBasicAdUploadPercentage(percentage));
    } else {
      cancel();
    }
  };

  publishBasicAdCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/publish`,
      method: 'POST',
    });
    return res.data;
  };

  unpublishBasicAdCampaign = async ({ campaignId, sponsorshipSetId, sponsorshipId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipset/${sponsorshipSetId}/sponsorship/${sponsorshipId}/unpublish`,
      method: 'POST',
    });

    return res.data;
  };

  activateBasicAdSponsorship = async ({ campaignId, adSetId, adId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-set/${adSetId}/ad/${adId}/activate`,
      method: 'PUT',
    });

    return res.data;
  };

  deactivateBasicAdSponsorship = async ({ campaignId, adSetId, adId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-set/${adSetId}/ad/${adId}/deactivate`,
      method: 'PUT',
    });

    return res.data;
  };

  createBasicAdSponsorship = async ({
    campaignId,
    adSetId,
    name,
    ctaUrl,
    ctaButtonText,
    thirdPartyTrackingScripts,
    CTAUpdateRequest,
    mediaType,
    additionalData,
    mediaFileName,
    mediaHeight,
    mediaWidth,
    mediaLength,
    caption,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-set/${adSetId}/ad`,
      method: 'POST',
      bodyType: 'json',
      body: {
        name,
        ctaUrl,
        ctaButtonText,
        mediaType,
        mediaFileName,
        mediaHeight,
        mediaWidth,
        mediaLength,
        caption,
        thirdPartyTrackingScripts: thirdPartyTrackingScripts ?? [],
        CTAUpdateRequest,
        ...additionalData,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  updateBasicAdSponsorship = async ({
    campaignId,
    adSetId,
    adId,
    name,
    ctaUrl,
    ctaButtonText,
    contentType,
    thirdPartyTrackingScripts,
    CTAUpdateRequest,
    mediaDetails,
    mediaType,
    ctaCoverFileName,
    additionalData,
    mediaFileName,
    mediaHeight,
    mediaWidth,
    mediaLength,
    caption,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-set/${adSetId}/ad/${adId}`,
      method: 'PUT',
      bodyType: 'json',
      body: {
        name,
        ctaUrl,
        ctaButtonText,
        mediaType,
        mediaLength,
        thirdPartyTrackingScripts: thirdPartyTrackingScripts ?? [],
        CTAUpdateRequest,
        caption,
        ...(mediaFileName ? { mediaFileName, mediaHeight, mediaWidth } : {}),
        ...additionalData,
      },
    });

    if (response?.data?.presgnedUrl) {
      const { presgnedUrl } = response?.data;
      const { media } = mediaDetails;

      await fetch(presgnedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: media,
      });
    }

    if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
      const error = new Error('Unable to update sponsorship');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getBasicAdCoverTilePreSignedUrl = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    filename,
    height,
    aspectRatio,
    width,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipsets/${sponsorshipSetId}/sponsorships/${sponsorshipId}/cover-tile-pre-signed-url`,
      method: 'POST',
      bodyType: 'json',
      body: {
        originalFileName: filename,
        mediaAspectRatio: aspectRatio,
        mediaWidth: width,
        mediaHeight: height,
      },
    });

    return response.data;
  };

  getBasicAdVideoPreSignedUrl = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    filename,
    height,
    aspectRatio,
    width,
    videoLength,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipsets/${sponsorshipSetId}/sponsorships/${sponsorshipId}/video-pre-signed-url`,
      method: 'POST',
      bodyType: 'json',
      body: {
        originalFileName: filename,
        mediaAspectRatio: aspectRatio,
        mediaWidth: width,
        mediaHeight: height,
        videoLength,
      },
      newVersionApi: '2.0',
    });

    return response.data;
  };

  uploadBasicAdCoverTile = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    media,
    adCover,
    mediaType,
  }) => {
    const { aspectRatio, height, width } = await getDimensions({
      media,
      mediaType,
      fileUrl: adCover,
    });

    const { preSignedUrl, ...response } = await this.getCoverTilePreSignedUrl({
      campaignId,
      sponsorshipSetId,
      sponsorshipId,
      aspectRatio,
      filename: media.name,
      height,
      mediaType,
      width,
    });

    await fetch(preSignedUrl, {
      method: 'PUT',
      body: media,
    });

    return response;
  };

  uploadBasicAdVideo = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    media,
    mediaType,
    adVideo,
    preSignedUrl,
    videoLength,
  }) => {
    const uploadRequest = axios.CancelToken.source();

    await axios({
      url: preSignedUrl,
      method: 'PUT',
      headers: {
        'Content-Type': mediaType,
      },
      data: media,
      cancelToken: uploadRequest.token,
      onUploadProgress: (event) => {
        return this.updateBasicAdPercentage(
          Math.round((event.loaded * 100) / media.size),
          uploadRequest.cancel
        );
      },
    });
  };

  getBasicAdVodInfoStatus = async ({ campaignId, sponsorshipSetId, sponsorshipId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-sets/${sponsorshipSetId}/ad/${sponsorshipId}/video-status`,
      method: 'GET',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  getBasicAdImageInfoStatus = async ({ campaignId, sponsorshipSetId, sponsorshipId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-sets/${sponsorshipSetId}/ad/${sponsorshipId}/image-status`,
      method: 'GET',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };
}
