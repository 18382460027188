import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { analyticsReducer } from './analytics';
import { authReducer } from './auth';
import { brandReducer } from './brand';
import { uiReducer } from './ui';
import { campaignReducer } from './campaign/campaign';
import { sponsorshipSetReducer } from './campaign/sponsorshipSet';
import { sponsorshipReducer } from './campaign/sponsorship';
import { summaryReducer } from './campaign/summary';
import { manageReducer } from './manage';
import { eightAuth } from './eightauth';
import { dataReducer } from './data/reducer';
import { basicAdReducer } from './basicAd/campaign/reducer';
import { basicAdSponsorshipSetReducer } from './basicAd/sponsorshipSet/reducer';
import { basicAdSponsorshipReducer } from './basicAd/sponsorship/reducer';
import { basicAdSummaryReducer } from './basicAd/summary/reducer';
import { basicAdDataReducer } from './basicAdData';
import { manageBasicAdsReducer } from './manageBasicAds';
import { basicAdAnalyticsReducer } from './analytics/basicAds/reducer';

export const createRootReducer = (history) =>
  combineReducers({
    analytics: analyticsReducer,
    auth: authReducer,
    brand: brandReducer,
    campaign: campaignReducer,
    sponsorshipSet: sponsorshipSetReducer,
    sponsorship: sponsorshipReducer,
    summary: summaryReducer,
    manage: manageReducer,
    manageBasicAds: manageBasicAdsReducer,
    data: dataReducer,
    ui: uiReducer,
    basicAd: basicAdReducer,
    basicAdSponsorshipSet: basicAdSponsorshipSetReducer,
    basicAdSponsorship: basicAdSponsorshipReducer,
    basicAdData: basicAdDataReducer,
    basicAdSummary: basicAdSummaryReducer,
    basicAdAnalytics: basicAdAnalyticsReducer,

    router: connectRouter(history),

    ...eightAuth.authReducer,
  });
