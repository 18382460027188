import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import UnpublishedChangesToastContent from '../../components/UnpublishChangesToastContent/UnpublishedChangesToastContent';
import { container } from '../../container';
import { brandInfoSelector } from '../brand';

import { isListEmpty } from '../../hooks/useLanguage/useLanguage';
import { transformUnpublishedDifferences } from '../../utils/basicAds/transformResponse';
import { setBasicAdEditId } from '../basicAd/campaign';
import {
  basicAdCampaignDataFetching,
  deleteBasicAdAd,
  deleteBasicAdAdSet,
  fetchBasicAdCampaignAsync,
  fetchBasicAdCampaignDifferencesAsync,
  setUnpublishedBasicAdDifferences,
} from './actions';
import { basicAdCampaignIdSelector } from './selectors';

const basicAdCampaignDataService = container.get('BasicAdCampaignDataService');

function* fetchBasicAdCampaignSaga({ payload }) {
  try {
    yield put(basicAdCampaignDataFetching(true));
    let { timezoneId } = yield select(brandInfoSelector);

    const response = yield call(basicAdCampaignDataService.getCompleteCampaign, {
      campaignId: payload,
      utcString: timezoneId,
    });

    const hasUnpublishedChanges = transformUnpublishedDifferences(response);

    yield put(fetchBasicAdCampaignAsync.success(response));
    yield put(setBasicAdEditId(response.id));
    if (!isListEmpty(hasUnpublishedChanges)) {
      toast(<UnpublishedChangesToastContent />, {
        containerId: 'unpublished-changes',
        toastId: 'unpublished-toast',
        closeButton: false,
      });
      yield put(setUnpublishedBasicAdDifferences(hasUnpublishedChanges));
    }

    if (!response.isDraft) yield put(fetchBasicAdCampaignDifferencesAsync.request(payload));
    yield put(basicAdCampaignDataFetching(false));
  } catch (error) {
    yield put(basicAdCampaignDataFetching(false));
  }
}

function* fetchBasicAdCampaignDifferencesSaga({ payload }) {
  try {
    yield put(basicAdCampaignDataFetching(true));
    // const response = yield call(basicAdCampaignDataService.getDifferences, {
    //   campaignId: payload,
    // });

    // if (response.length)
    //   toast(<UnpublishedChangesToastContent />, {
    //     containerId: 'unpublished-changes',
    //     toastId: 'unpublished-toast',
    //     closeButton: false,
    //   });

    // yield put(setUnpublishedBasicAdDifferences(response));
    yield put(basicAdCampaignDataFetching(false));
  } catch (error) {
    yield put(basicAdCampaignDataFetching(false));
  }
}

function* deleteBasicAdAdSetSaga({ payload }) {
  try {
    const campaignId = yield select(basicAdCampaignIdSelector);
    if (payload.adSetId !== 'new')
      yield call(basicAdCampaignDataService.deleteAdSet, {
        campaignId,
        adSetId: payload.adSetId,
      });
  } catch (error) {}
}

function* deleteBasicAdAdSaga({ payload }) {
  try {
    const campaignId = yield select(basicAdCampaignIdSelector);
    if (payload.adId !== 'new')
      yield call(basicAdCampaignDataService.deleteAd, {
        campaignId,
        adSetId: payload.adSetId,
        adId: payload.adId,
      });
  } catch (error) {}
}

export function* basicAdCampaignDataActionWatcher() {
  yield takeLatest(fetchBasicAdCampaignAsync.request.type, fetchBasicAdCampaignSaga);
  yield takeLatest(deleteBasicAdAdSet, deleteBasicAdAdSetSaga);
  yield takeLatest(deleteBasicAdAd, deleteBasicAdAdSaga);
  yield takeLatest(
    fetchBasicAdCampaignDifferencesAsync.request.type,
    fetchBasicAdCampaignDifferencesSaga
  );
}
