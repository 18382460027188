import React, { useState } from 'react';
import s from '../../ReportingFilters.module.scss';
import { SvgIcon } from '../../../../../common/SvgIcon';
import { useT } from '@transifex/react';

const DownloadCSVButton = ({ handleDownloadCSV, getValues }) => {
  const [isHovered, setIsHovered] = useState(false);
  const t = useT();

  return (
    <div className={s['buttons-report']} onMouseLeave={() => setIsHovered(false)}>
      <div
        className={`${s['csv-container']} ${isHovered ? s['show-options'] : ''}`}
        onMouseOver={() => setIsHovered(true)}
      >
        <div className={s['csv-link']}>
          <div className={s['link-text']}>{t('Download report')}</div>
          <div className={s['buttons-report__icon']}>
            <SvgIcon name="csv-download" />
          </div>
        </div>
        <ul className={s['download-options']}>
          <li onClick={handleDownloadCSV(null, null, getValues())}>Full Report</li>
          {/* <li onClick={handleDownloadCSV('completed', null, getValues())}>Completed Report</li>
          <li onClick={handleDownloadCSV('accepted', null, getValues())}>Accepted Report</li>
          <li onClick={handleDownloadCSV('declined', null, getValues())}>Declined Report</li>
          <li onClick={handleDownloadCSV('viewedInFeed', null, getValues())}>Feed View Report</li>
          <li onClick={handleDownloadCSV('videoViewed', null, getValues())}>Video View Report</li> */}
        </ul>
      </div>
    </div>
  );
};

export default DownloadCSVButton;
