import config from '../../../config';

export class BasicAdService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  activateBasicAdCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${campaignId}/activate`,
      method: 'PUT',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  deactivateBasicAdCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/deactivate`,
      method: 'PUT',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  archiveBasicAdCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${campaignId}/archive`,
      method: 'PUT',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  unarchiveBasicAdCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${campaignId}/archive`,
      method: 'PUT',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  createBasicAdCampaign = async (payload) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign`,
      method: 'POST',
      bodyType: 'json',
      body: {
        name: payload.name,
        campaignBrandName: payload.campaignBrandName,
        charityId: payload.charityId,
        objective: payload.category,
        ...(payload.campaignBrandLogoFile
          ? { campaignBrandLogoFileName: encodeURI(payload.campaignBrandLogoFile.name) }
          : {}),
      },
    });

    const preSignedUrl = res.data?.preSignedUrlDetails?.preSignedUrl;

    if (preSignedUrl) {
      await fetch(preSignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': payload.campaignBrandLogoFile.type,
        },
        body: payload.campaignBrandLogoFile,
      });
    }

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  updateBasicAdCampaign = async (payload) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${payload.campaignId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: {
        id: payload.campaignId,
        name: payload.name,
        campaignBrandName: payload.campaignBrandName,
        charityId: payload.charityId,
        objective: payload.category,
        ...(payload.campaignBrandLogoFile
          ? { campaignBrandLogoFileName: encodeURI(payload.campaignBrandLogoFile.name) }
          : {}),
      },
    });

    const preSignedUrl = res.data?.preSignedUrlDetails?.preSignedUrl;

    if (preSignedUrl) {
      await fetch(preSignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': payload.campaignBrandLogoFile.type,
        },
        body: payload.campaignBrandLogoFile,
      });
    }

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  duplicateBasicAdCampaign = async ({ campaignId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/duplicate-request`,
      method: 'POST',
      bodyType: 'json',
      body: {
        campaignId,
      },
    });

    return response.data;
  };

  getBasicAdCampaignDuplicationStatus = async ({ duplicateRequestId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/duplicate-status/${duplicateRequestId}`,
      method: 'GET',
      bodyType: 'json',
    });

    return response.data;
  };

  submitBasicAdCampaignNewCharity = async ({ newCharityName, newCharityWebsite }) => {
    const res = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/add-charity-request`,
      method: 'POST',
      bodyType: 'json',
      body: {
        charityName: newCharityName,
        charityWebsite: newCharityWebsite,
      },
    });

    if (res.status !== 200 && res.status !== 201) {
      throw new Error('Unable to send request for new Charity');
    }

    return res.data;
  };
}
