import config from '../../config';
import {
  processBrandRole,
  getUrlFragment,
  formatDateUTC,
  processTags,
  processInterests,
  processPassions,
} from '../../utils/receivedData';
import { routes } from '../../constants';
import { getDimensions } from '../../utils/media/media-utils';

export class BrandService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  sendNewPaymentCard = async ({ paymentMethod }) => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/brands/stripe/add-credit-card/${paymentMethod}`,
      method: 'POST',
      bodyType: 'json',
      body: {},
    });
    return response.data;
  };

  fetchAccountBalance = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/v2/brands/wallet-info`,
      method: 'GET',
    });

    const { balance } = response.data;

    return balance;
  };

  fetchBrand = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me`,
      method: 'GET',
    });

    const {
      id,
      countryCode,
      currencyCode,
      logoUri,
      emailAddress,
      name,
      role,
      timezoneId,
      website,
      isInvoicePartner,
      brandType,
      legalName,
      billingEmail,
      address,
      phoneNumber,
      bcP47LanguageTag,
      accountStatus,
      vatReceiptUri,
      accountType,
      samiPricingId,
      basicAdsPricingId,
    } = response.data;

    return {
      id,
      countryCode,
      currencyCode,
      emailAddress,
      logoUri: logoUri ?? '',
      brandName: name,
      role: role ? processBrandRole(role) : '',
      signupCode: '',
      website,
      timezoneId,
      isInvoicePartner,
      billingEmail: billingEmail || '',
      legalName: legalName || '',
      brandType: brandType || '',
      addressLineFirst: address?.addressLine1 || '',
      addressLineSecond: address?.addressLine2 || '',
      city: address?.city || '',
      postCode: address?.postalCode || '',
      brandCountryCode: address?.countryCode || '',
      phone: {
        regionCode: phoneNumber?.regionCode ? phoneNumber?.regionCode : '',
        number: phoneNumber?.number
          ? `${'+'}${phoneNumber?.countryCode}${phoneNumber?.number}`
          : '',
      },
      bcP47LanguageTag,
      accountStatus,
      vatReceiptUri,
      accountType: accountType || '',
      samiPricingId,
      basicAdsPricingId,
    };
  };

  fetchInterests = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/interests`,
      method: 'GET',
    });

    return processInterests(response.data);
  };

  fetchPassions = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/passions`,
      method: 'GET',
    });

    return processPassions(response.data);
  };

  fetchTags = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/tags?limit=${limit}&offset=${offset}&search=${keyword}`,
      method: 'GET',
      newVersionApi: '2.0',
    });

    return { ...response.data, data: processTags(response.data.data) };
  };

  fetchCharities = async (countryCode) => {
    const response = await this.fetchService.fetch({
      url: `${config.charitiesAPI}/query/campaign-donation-partners/countries/${countryCode}`,
      method: 'GET',
    });

    return response.data;
  };

  fetchTimezones = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.adminAPI}/timezone-infos`,
      method: 'GET',
      bodyType: 'json',
    });

    return response.data;
  };

  fetchTimezone = async ({ utcString }) => {
    const response = await this.fetchService.fetch({
      url: `${config.adminAPI}/timezone-infos/by-utc-string?utcString=${utcString}`,
      method: 'GET',
      bodyType: 'json',
    });

    return response.data;
  };

  fetchLocations = async ({ countryCode, search }) => {
    const response = await this.fetchService.fetch({
      url: `${config.adminAPI}/geo/country/${countryCode}/${search}`,
      method: 'GET',
      newVersionApi: '2.0',
    });

    const regions = response.data.filter((item) => item.context === 'region');

    const cities = response.data.filter((item) => item.context === 'city');

    const data = regions.map((region) => {
      let citiesOfRegion = cities.filter((city) => city.regionName === region.regionName);
      citiesOfRegion = citiesOfRegion.map((city) => {
        return {
          label: city.city,
          value: city.tag,
          region: region.tag,
        };
      });
      return {
        label: region.regionName,
        value: region.tag,
        cities: citiesOfRegion,
      };
    });

    return data;
  };

  fetchBrandById = async ({ id }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/${id}`,
      method: 'GET',
    });

    return response.data;
  };

  getBrandLogoPreSignedUrl = async ({ aspectRatio, filename, height, width }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/brand-logo-pre-signed-url`,
      method: 'POST',
      bodyType: 'json',
      body: {
        originalFileName: filename,
        mediaAspectRatio: aspectRatio,
        mediaWidth: width,
        mediaHeight: height,
      },
    });

    return response.data;
  };

  uploadBrandMedia = async ({ media, mediaType, logoUri }) => {
    const { aspectRatio, height, width } = await getDimensions({
      media,
      mediaType,
      fileUrl: logoUri,
    });

    const { preSignedUrl, ...response } = await this.getBrandLogoPreSignedUrl({
      aspectRatio,
      filename: media.name,
      height,
      mediaType,
      width,
    });

    await fetch(preSignedUrl, {
      method: 'PUT',
      body: media,
    });

    return response;
  };

  getCountries = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.adminAPI}/locations/countries/supported`,
      method: 'GET',
      useMachineToken: true,
      newVersionApi: '2.0',
    });

    return response.data.map((country) => ({
      name: country.name,
      label: country.name,
      value: country.name,
      currencyCode: country.currencies[0].code,
      currencySymbol: country.currencies[0].symbol,
      countryCode: country.id,
      dialCodes: country.dialCodes,
      defaultTimezoneId: country.defaultTimezoneId,
      timezones: country.timezones.map((timezone) => ({
        label: timezone,
        name: timezone,
        value: timezone,
      })),
      languages: country.languages,
    }));
  };

  getBasicAdPricing = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/brand/default-price-card`,
      method: 'GET',
    });

    return response.data?.prices;
  };

  getPricing = async (samiPricingId) => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/v2/watchandrespond-fee/sami-pricing/${samiPricingId}`,
      method: 'GET',
    });

    return response.data?.prices;
  };

  getPixelStats = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/brand-eight-pixel-stats`,
      method: 'GET',
    });

    return response.data;
  };

  signUpBrand = async ({
    name,
    countryCode,
    emailAddress,
    password,
    signupCode,
    timezoneId,
    website,
    BCP47LanguageTag,
    logoFileName,
    acceptedTermsOfUse,
  }) => {
    const res = await this.fetchService.fetch({
      method: 'POST',
      url: `${config.usersAPI}/brands`,
      newVersionApi: '5.0',
      useMachineToken: true,
      body: {
        countryCode,
        emailAddress,
        name,
        password,
        signupCode,
        timezoneId,
        website,
        BCP47LanguageTag,
        logoFileName,
        acceptedTermsOfUse,
      },
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  updateBrandAccountInfo = async ({ emailAddress, name, countryCode, timezone, website }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/profile`,
      method: 'POST',
      bodyType: 'json',
      body: {
        emailAddress,
        name,
        countryCode,
        timezone,
        website,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  updateBrandProfileInfo = async ({ bodyParams, profileInfoType }) => {
    const urlFragment = getUrlFragment(profileInfoType);

    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/${urlFragment}`,
      method: 'POST',
      bodyType: 'json',
      body: bodyParams,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  updateBusinessDetails = async ({
    address,
    billingEmail,
    accountType,
    vatReceiptFile,
    legalName,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/business-settings`,
      method: 'POST',
      bodyType: 'json',
      body: {
        address,
        billingEmail,
        accountType,
        vatReceiptFileName: vatReceiptFile?.name,
        legalName,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    if (response.data?.brandDocPreSignedUrlDetail?.preSignedUrl)
      await fetch(response.data?.brandDocPreSignedUrlDetail?.preSignedUrl, {
        method: 'PUT',
        body: vatReceiptFile,
      });

    return response.data;
  };

  getCardSetupIntentKey = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/brands/stripe/setup-credit-card-intent`,
      method: 'POST',
      body: {
        idempotencyKey: null,
      },
    });

    return response.data.clientSecret;
  };

  getBacsCheckoutSessionId = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/brands/stripe/create-bacs-checkout-session`,
      method: 'POST',
      body: {
        idempotencyKey: null,
        stripeBacsDebitSuccessRoute: `${window.location.origin}${routes.STRIPE_SUCCESS}`,
        stripeBacsDebitCancelRoute: `${window.location.origin}${routes.STRIPE_CANCEL}`,
      },
    });

    return response.data;
  };

  getPaymentMethods = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/brands/stripe/payment-methods`,
      method: 'GET',
    });

    const newPaymentMethods = response.data.map((method) => {
      const methodName = method?.cardBrand ? String(method?.cardBrand).toUpperCase() : 'BANK';
      return {
        ...method,
        name: `${methodName} - ${method.cardLast4 || method.bacsDebitLast4}`,
        label: `${methodName} - ${method.cardLast4 || method.bacsDebitLast4}`,
        value: method.paymentMethodId,
      };
    });

    return newPaymentMethods;
  };

  removePaymentMethod = async ({ paymentMethodId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/brands/stripe/remove-payment-method/${paymentMethodId}`,
      method: 'DELETE',
    });

    return response.data;
  };

  makeMethodPrimary = async ({ paymentMethodId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/brands/stripe/change-active-payment-method/${paymentMethodId}`,
      method: 'POST',
    });

    return response.data;
  };

  chargePaymentMethod = async ({ bodyParams, invoiceId }) => {
    const urlFragment = invoiceId ? `pay-bill` : `add-funds`;
    const body = invoiceId
      ? {
          ...bodyParams,
          idempotencyKey: null,
          invoiceId,
        }
      : {
          ...bodyParams,
          idempotencyKey: null,
        };

    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/v2/brands/${urlFragment}`,
      method: 'POST',
      body,
    });

    return response.data;
  };

  getBrandBills = async ({ offset = 0, limit }) => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/v2/brands/bills?offset=${offset}&limit=${limit}`,
      method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error(`Unable to get data from server`);
      error.statusCode = response.status || 500;
      throw error;
    }

    const newData = response.data.data
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      .map((item) => {
        return {
          ...item,
          billStatus: item.billStatus ? item.billStatus.split('_').join(' ') : '',
          billedMonth: formatDateUTC(item.billedMonth, 'LLL, yyyy'),
        };
      });
    response.data.data = newData;
    return response.data;
  };

  getBrandTransactions = async ({ offset = 0, limit }) => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/v2/brands/payments/transaction-history?offset=${offset}&limit=${limit}`,
      method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error(`Unable to get data from server`);
      error.statusCode = response.status || 500;
      throw error;
    }

    const newData = response.data.data.sort((a, b) => new Date(b.date) - new Date(a.date));

    response.data.data = newData;
    return response.data;
  };

  getVatAndFee = async ({ amount }) => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/v2/brands/payments/vat-and-fees`,
      method: 'POST',
      body: {
        amount,
      },
    });

    return response.data;
  };

  getLastStripeCharge = async () => {
    const response = await this.fetchService.fetch({
      url: `${config.paymentsAPI}/v2/brands/payments/last-stripe-charge`,
      method: 'GET',
    });

    if (response.status === 204) {
      return {
        total: null,
        paymentMethod: '',
        date: '',
      };
    }

    const { total, paymentMethod, date } = response.data;

    return {
      total,
      paymentMethod,
      date,
    };
  };

  invoicePartnerRequest = async (bodyParams) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/invoice-partner-request`,
      method: 'POST',
      body: bodyParams,
    });

    return response.data;
  };

  updateBrandPhone = async ({ regionCode, number }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/phone`,
      method: 'POST',
      bodyType: 'json',
      body: {
        regionCode,
        number,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  sendVerificationCode = async ({ emailAddress }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/send-verification-code`,
      method: 'POST',
      bodyType: 'json',
      body: { emailAddress },
      useMachineToken: true,
    });

    return response.data;
  };

  sendResetPasswordEmail = async ({ emailAddress }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/password-reset-request`,
      method: 'POST',
      bodyType: 'json',
      body: { emailAddress },
      useMachineToken: true,
    });

    return response.data;
  };

  resetPassword = async ({ newPassword, key, emailAddress }) => {
    const response = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/reset-password`,
      method: 'POST',
      bodyType: 'json',
      body: { newPassword, key, emailAddress },
      useMachineToken: true,
    });

    return response.data;
  };

  getCampaignNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/campaigns/names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
      newVersionApi: '2.0',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getBasicCampaignNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v1/basic-ads/campaigns/names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getUniqueCampaignNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/campaigns/unique-names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getUniqueBasicCampaignNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v1/basic-ads/campaigns/unique-names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getSponsorshipSetNames = async ({ limit, offset, keyword, campaignId }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/campaigns/sponsorshipsets/names?limit=${limit}&offset=${offset}&keyword=${keyword}&campaignId=${campaignId}`,
      newVersionApi: '2.0',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getBasicSponsorshipSetNames = async ({ limit, offset, keyword, campaignId }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-sets/names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getUniqueSponsorshipSetNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/campaigns/sponsorshipsets/unique-names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getUniqueBasicSponsorshipSetNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v2/basic-ads/ad-sets/unique-names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getSponsorshipNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/campaigns/sponsorships/names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
      newVersionApi: '2.0',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getUniqueSponsorshipNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/campaigns/sponsorships/unique-names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getUniqueBasicSponsorshipNames = async ({ limit, offset, keyword }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v2/basic-ads/ads/unique-names?limit=${limit}&offset=${offset}&keyword=${keyword}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getTestPixelEvents = async ({ limit, offset, minDate }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.analyticsAPI}/eight-pixel/testing?limit=${limit}&offset=${offset}${
        minDate ? `&timestamp=${minDate}` : ''
      }`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };
}
