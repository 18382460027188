import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NameAndAudience } from '../../../../../components/PagesContent/CreateContent/NameAndAudience';
import { TimingAndBudget } from '../../../../../components/PagesContent/CreateContent/TimingAndBudget';
import { ScrollToTopWrapper } from '../../../../../components/wrappers/ScrollToTopWrapper';

import { fragments, routes } from '../../../../../constants';

import { budgetFormatter } from '../../../../../utils/budget';

import preventSubmitOnEnter from '../../../../../utils/preventSubmitOnEnter';

import { modalsSelector, savingToastSelector, setStickyMenuItem } from '../../../../../store/ui';

import {
  activateBasicAdSponsorshipSetFromCreationAsync,
  basicAdSponsorshipSetCreateSelector,
  basicAdSponsorshipSetEditIdSelector,
  createBasicAdSponsorshipSetAsync,
  deactivateBasicAdSponsorshipSetFromCreationAsync,
  setBasicAdSponsorshipSetData,
  toggleBasicAdActivateSponsorshipSet,
  updateBasicAdSponsorshipSetAsync,
} from '../../../../../store/basicAd/sponsorshipSet';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { generatePath } from 'react-router';
import { useUnmount } from 'react-use';
import { Layout } from '../../../../../components/Layout';
import { StepButtons } from '../../../../../components/StepButtons';
import sponsorshipSetConstants from '../../../../../constants/basicAd/sponsorshipSet-data';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import useBasicAdActiveForm from '../../../../../hooks/useBasicAdActiveForm/useBasicAdActiveForm';
import { useDirtyFields } from '../../../../../hooks/useDirtyFields';
import { useInfiniteScroll } from '../../../../../hooks/useInfiniteScroll';
import { useRouter } from '../../../../../hooks/useRouter';
import {
  basicAdAdSetsSelector,
  basicAdCampaignDataFetchingSelector,
  basicAdCampaignIdSelector,
  basicAdCampaignSelector,
  setBasicAdAdSetIsDirty,
} from '../../../../../store/basicAdData';
import {
  brandInfoSelector,
  fetchTagsAsync,
  fetchUniqueBasicSponsorshipSetNamesAsync,
  incentivesSelector,
  locationsSelector,
  previousUniqueBasicSponsorshipSetsNamesSelector,
} from '../../../../../store/brand';
import { constructTestId } from '../../../../../utils/test-ids';
import { SponsorshipSetPlaceholder } from '../../../../Placeholders/SponsorshipSetPlaceholder';
import { createModeValidationSchema, editModeValidationSchema } from './validationSchema';
import { getDeliveryStatus } from '../../../../../utils/basicAds/helpers';

const SECTION = CREATE_CAMPAIGN_PAGE.NAME_AND_AUDIENCE;

const SponsorshipSetForm = () => {
  const dispatch = useDispatch();
  const [clickedOnSave, setClickedOnSave] = useState(false);

  const brandInfo = useSelector(brandInfoSelector);
  const basicAdId = useSelector(basicAdCampaignIdSelector);
  const fetching = useSelector(basicAdCampaignDataFetchingSelector);
  const modals = useSelector(modalsSelector);
  const sponsorshipSetDraft = useSelector(basicAdSponsorshipSetCreateSelector);
  const sponsorshipSetId = useSelector(basicAdSponsorshipSetEditIdSelector);
  const previousSponsorshipSetsNames = useSelector(previousUniqueBasicSponsorshipSetsNamesSelector);
  const basicAdCreate = useSelector(basicAdCampaignSelector);
  const saving = useSelector(savingToastSelector).isOpen;
  const regionsAndCities = useSelector(locationsSelector);
  const allAdSets = useSelector(basicAdAdSetsSelector);
  const incentives = useSelector(incentivesSelector);

  const { deliveryStatus } = sponsorshipSetDraft;

  const adSetLockedWithDeliveryStatus = getDeliveryStatus(deliveryStatus);
  const { query, push } = useRouter();
  const [adsetId] = useState(query.ssid !== 'new' ? query.ssid : null);
  useBasicAdActiveForm({
    type: 'adSet',
    campaignId: query.cid,
    adSetId: query.ssid,
    adSetNewIndex: query.newIndex,
    adId: query.sid,
    allAdSets,
  });
  const ssidRef = useRef(query.ssid);

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    control,
    reset,
    getValues,
    watch,
    trigger,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: sponsorshipSetDraft.name,
      gender: sponsorshipSetDraft.gender,
      age: sponsorshipSetDraft.age,
      locations: sponsorshipSetDraft.locations.length > 0 ? sponsorshipSetDraft.locations : [],
      locationsStatus: sponsorshipSetDraft.locations.length > 0,
      interests: sponsorshipSetDraft.interests,
      interestsStatus: sponsorshipSetDraft.interests.length > 0,
      passions: sponsorshipSetDraft.passions,
      passionsStatus: sponsorshipSetDraft.passions.length > 0,
      eightPixelAudiences: sponsorshipSetDraft.eightPixelAudiences,
      eightPixelAudiencesStatus: sponsorshipSetDraft.eightPixelAudiences.length > 0,
      deviceType: sponsorshipSetDraft.deviceType,
      frequencyCap: sponsorshipSetDraft.frequencyCap ?? 1,
      frequencyCapStatus: Boolean(sponsorshipSetDraft.frequencyCap),
      isEightPixelEnabled: sponsorshipSetDraft.isEightPixelEnabled,
      pixelConverstionEvent: sponsorshipSetDraft.pixelConversionEvent,
      customAudiences: sponsorshipSetDraft.customAudiencesSelected,
      startDate: sponsorshipSetDraft.startDate,
      endDate: sponsorshipSetDraft.endDate,
      budget: sponsorshipSetDraft.budget
        ? budgetFormatter(sponsorshipSetDraft.budget)
        : sponsorshipSetDraft.budget,
      charitiesAreas: sponsorshipSetDraft.charitiesAreas,
      selectedCharity: sponsorshipSetDraft.selectedCharity,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    context: {
      brandUtcString: brandInfo.brandTimezone.utcString,
      isEdit: !sponsorshipSetDraft.isDraft,
    },
    resolver:
      sponsorshipSetDraft.isReadOnly || sponsorshipSetId
        ? yupResolver(editModeValidationSchema)
        : yupResolver(createModeValidationSchema),
  });

  useEffect(() => {
    reset(
      {
        name: sponsorshipSetDraft.name,
        gender: sponsorshipSetDraft.gender,
        age: sponsorshipSetDraft.age,
        locations: sponsorshipSetDraft.locations.length > 0 ? sponsorshipSetDraft.locations : [],
        locationsStatus: sponsorshipSetDraft.locations.length > 0,
        interests: sponsorshipSetDraft.interests,
        interestsStatus: sponsorshipSetDraft.interests.length > 0,
        passions: sponsorshipSetDraft.passions,
        passionsStatus: sponsorshipSetDraft.passions.length > 0,
        eightPixelAudiences: sponsorshipSetDraft.eightPixelAudiences,
        eightPixelAudiencesStatus: sponsorshipSetDraft.eightPixelAudiences.length > 0,
        includeTags: sponsorshipSetDraft.includeTags,
        excludeTags: sponsorshipSetDraft.excludeTags,
        deviceType: sponsorshipSetDraft.deviceType,
        frequencyCap: sponsorshipSetDraft.frequencyCap,
        frequencyCapStatus: Boolean(sponsorshipSetDraft.frequencyCap),
        isEightPixelEnabled: Boolean(sponsorshipSetDraft.isEightPixelEnabled),
        pixelConversionEvent:
          sponsorshipSetDraft.pixelConversionEvent === 'unknown'
            ? undefined
            : sponsorshipSetDraft.pixelConversionEvent,
        customAudiences: sponsorshipSetDraft.customAudiencesSelected,
        startDate: sponsorshipSetDraft.startDate,
        endDate: sponsorshipSetDraft.endDate,
        budget: sponsorshipSetDraft.budget
          ? budgetFormatter(sponsorshipSetDraft.budget)
          : sponsorshipSetDraft.budget,
        charitiesAreas: sponsorshipSetDraft.charitiesAreas,
        selectedCharity: sponsorshipSetDraft.selectedCharity,
      },
      { keepDirtyValues: true, keepDirty: true }
    );
  }, [
    reset,
    sponsorshipSetDraft.name,
    sponsorshipSetDraft.isActive,
    sponsorshipSetDraft.regionsAndCities,
    sponsorshipSetDraft.locations,
    sponsorshipSetDraft.interests,
    sponsorshipSetDraft.passions,
    sponsorshipSetDraft.excludeTags,
    sponsorshipSetDraft.deviceType,
    sponsorshipSetDraft.customAudiencesSelected,
    sponsorshipSetDraft.age,
    sponsorshipSetDraft.gender,
    sponsorshipSetDraft.startDate,
    sponsorshipSetDraft.endDate,
    sponsorshipSetDraft.budget,
    sponsorshipSetDraft.charitiesAreas,
    sponsorshipSetDraft.selectedCharity,
    sponsorshipSetDraft.isUnite,
    sponsorshipSetDraft.incentive,
    brandInfo.videoAdPricingData,
    sponsorshipSetDraft.isEightPixelEnabled,
    sponsorshipSetDraft.pixelConversionEvent,
    sponsorshipSetDraft.includeTags,
    basicAdCreate?.category,
    sponsorshipSetDraft.eightPixelAudiences,
    brandInfo.incentive,
    sponsorshipSetDraft.videoDurationAllowedRange,
    incentives,
    sponsorshipSetDraft.isDraft,
    sponsorshipSetDraft.frequencyCap,
  ]);

  const { dirtyFieldsList, shouldSave } = useDirtyFields({
    storedDirtyFields: sponsorshipSetDraft?.dirtyFields,
    formDirtyFields: dirtyFields,
    fieldNames: sponsorshipSetConstants.fieldNames,
    isDirty,
  });

  // This runs when we switch to another entity or unmount (the component does unmount)
  useUnmount(() => {
    if (shouldSave && !clickedOnSave) {
      dispatch(
        setBasicAdAdSetIsDirty({
          isDirty: true,
          dirtyFields: { ...dirtyFields, ...sponsorshipSetDraft.dirtyFields },
          form: getValues(),
          adSetId: ssidRef.current ?? sponsorshipSetDraft?.id,
        })
      );
      dispatch(setBasicAdSponsorshipSetData({ name: 'id', value: '' }));
    }
    dispatch(setBasicAdSponsorshipSetData({ name: 'dirtyFields', value: {} }));
  });

  const timingRef = useRef();

  // HACK! Scroll on startDate field when it's invalid. Works for now, should fix later!!!
  const handleError = (err) => {
    (err.startDate || err.endDate) && timingRef.current.scrollIntoView();
  };

  const handleFormSubmit = () => {
    setClickedOnSave(true);
    if (adsetId) {
      const payload = { ...getValues(), adsetId, basicAdId };
      dispatch(updateBasicAdSponsorshipSetAsync.request(payload));
    } else if (shouldSave) {
      const payload = { ...getValues(), basicAdId };

      dispatch(
        createBasicAdSponsorshipSetAsync.request({
          form: payload,
          adSetIndex: parseInt(query.newIndex) - 1,
          dirtyFields: { ...dirtyFields, ...sponsorshipSetDraft.dirtyFields },
        })
      );
    } else {
      push(
        generatePath(routes.SPONSORSHIP_EDIT, {
          id: basicAdId,
          ssid: sponsorshipSetDraft.id,
          sid: sponsorshipSetDraft.ads[0].id,
        }) + fragments.AD_NAME
      );
    }
  };
  //us-east-1.console.aws.amazon.com/console/home?region=us-east-1#

  useEffect(() => () => dispatch(setStickyMenuItem(null)), [dispatch]);

  const handlePreviousStep = useCallback(() => {
    push(
      generatePath(routes.BASIC_AD.EDIT_CAMPAIGN, {
        cid: basicAdId,
      }) + fragments.OBJECTIVE
    );
  }, [basicAdId, push]);

  const handleAdSetActivation = useCallback(
    (e) => {
      const checked = e.target.checked;
      dispatch(toggleBasicAdActivateSponsorshipSet(checked));
      dispatch(
        checked
          ? activateBasicAdSponsorshipSetFromCreationAsync.request({
              campaignId: query.cid,
              adSetId: sponsorshipSetDraft.id || query.ssid,
            })
          : deactivateBasicAdSponsorshipSetFromCreationAsync.request({
              campaignId: query.cid,
              adSetId: sponsorshipSetDraft.id || query.ssid,
            })
      );
    },
    [dispatch, query.cid, query.ssid, sponsorshipSetDraft.id]
  );

  const {
    moreToFetch: moreNamesToFetch,
    fetchMore: fetchMoreNames,
    setKeyword: setNamesKeyword,
  } = useInfiniteScroll(
    fetchUniqueBasicSponsorshipSetNamesAsync.request,
    previousSponsorshipSetsNames
  );

  const {
    moreToFetch: moreTagsToFetch,
    fetchMore: fetchMoreTags,
    setKeyword: setTagsKeyword,
    fetchInitials: fetchInitialTags,
  } = useInfiniteScroll(fetchTagsAsync.request, brandInfo.fetchedTags, true);

  return (
    <Layout>
      <form onSubmit={handleSubmit(handleFormSubmit, handleError)} onKeyDown={preventSubmitOnEnter}>
        <ScrollToTopWrapper>
          {fetching ? (
            <SponsorshipSetPlaceholder />
          ) : (
            <>
              <NameAndAudience
                fetchMoreSponsorshipSetNames={fetchMoreNames}
                moreSponsorshipSetNamesToFetch={moreNamesToFetch}
                setNamesKeyword={setNamesKeyword}
                fetchInitialTags={fetchInitialTags}
                fetchMoreTags={fetchMoreTags}
                moreTagsToFetch={moreTagsToFetch}
                setTagsKeyword={setTagsKeyword}
                brandInfo={brandInfo}
                sponsorshipSetDraft={sponsorshipSetDraft}
                previousSponsorshipSetsNames={previousSponsorshipSetsNames}
                objective={basicAdCreate?.category}
                control={control}
                errors={errors}
                watch={watch}
                register={register}
                setValue={setValue}
                trigger={trigger}
                handleAdSetActivation={handleAdSetActivation}
                regionsAndCities={regionsAndCities}
                clearErrors={clearErrors}
                adSetLockedWithDeliveryStatus={adSetLockedWithDeliveryStatus}
                dirtyFieldsList={dirtyFieldsList}
              />
              <TimingAndBudget
                campaignId={basicAdId}
                brandInfo={brandInfo}
                modals={modals}
                sponsorshipSetDraft={sponsorshipSetDraft}
                videoAdPricingCheckboxes={brandInfo.videoAdPricingData}
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                trigger={trigger}
                timingRef={timingRef}
                basicAdCreate={basicAdCreate}
                adSetLockedWithDeliveryStatus={adSetLockedWithDeliveryStatus}
                clearErrors={clearErrors}
              />
            </>
          )}
        </ScrollToTopWrapper>
        <StepButtons
          data-testid={constructTestId(SECTION, 'continue-button')}
          handlePreviousStep={handlePreviousStep}
          saving={saving}
          isSubmitDisabled={!shouldSave}
        />
      </form>
    </Layout>
  );
};

export default SponsorshipSetForm;
