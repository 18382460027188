import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { Checkbox } from '../../../../FormElements/Checkbox';
import { ReportingSelect } from '../../../../FormElements/Selects/ReportingSelect';

import { Button } from '../../../../common/Button';
import { SvgIcon } from '../../../../common/SvgIcon';

import { setInputDateFormat } from '../../../../../utils/receivedData';
import { overviewFiltersValidationSchema } from './overviewFiltersValidationSchema';

import { useT } from '@transifex/react';
import { endOfDay } from 'date-fns';
import { useRouter } from '../../../../../hooks/useRouter';
import s from '../ReportingFilters.module.scss';
import DownloadCSVButton from '../components/DownloadCSVButton/DownloadCSVButton';

const OverviewFilters = ({
  initialFetchedData,
  filterOptions,
  overviewFilterRequest,
  handleDownloadCSV,
  countryCode,
  type,
  filteringDatesRange,
  brandIdFromAdmin,
}) => {
  const { query } = useRouter();

  const campaignId = query.cid;
  const adSetId = query.ssid;
  const adId = query.sid;

  const t = useT();
  const { handleSubmit, control, reset, getValues, setValue } = useForm({
    defaultValues: {
      selectedGender: filterOptions?.overview?.selectedGender,
      selectedDevice: filterOptions?.overview?.selectedDevice,
      selectedCity: filterOptions?.overview?.selectedCity,
      ageFrom: filterOptions?.overview?.ageFrom,
      ageTo: filterOptions?.overview?.ageTo,
      allAges: false,
      fromDate: filterOptions?.overview?.dateFrom,
      toDate: filterOptions?.overview?.dateTo,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(overviewFiltersValidationSchema),
  });

  const watchFromDate = useWatch({ control, name: 'fromDate' });
  const watchToDate = useWatch({ control, name: 'toDate' });
  const watchAllAges = useWatch({ control, name: 'allAges' });

  const ageRanges = useMemo(() => {
    const lastItemIndex = initialFetchedData?.agesOptions.length - 1;
    return {
      minAge: initialFetchedData?.agesOptions[0],
      maxAge: initialFetchedData?.agesOptions[lastItemIndex],
    };
  }, [initialFetchedData]);

  const hanldeAllAgesChange = useCallback(
    (e, onChange) => {
      if (e?.target?.checked) {
        setValue('ageFrom', ageRanges?.minAge);
        setValue('ageTo', ageRanges?.maxAge);
      }
      onChange(e.target.checked);
    },
    [ageRanges, setValue]
  );

  useEffect(() => {
    if (!watchFromDate && !watchToDate)
      reset({
        fromDate: filterOptions?.overview?.dateFrom,
        toDate: filterOptions?.overview?.dateTo,
      });
  }, [
    reset,
    watchFromDate,
    watchToDate,
    filterOptions?.overview?.dateFrom,
    filterOptions?.overview?.dateTo,
  ]);

  const resetForm = useCallback(() => {
    reset();
    overviewFilterRequest({ query: getValues(), campaignId, adSetId, adId, type });
  }, [reset, overviewFilterRequest, getValues, campaignId, adSetId, adId, type]);

  const handleSubmitOverviewFilter = useCallback(() => {
    overviewFilterRequest({ query: getValues(), campaignId, adSetId, adId, type });
  }, [overviewFilterRequest, getValues, campaignId, adSetId, adId, type]);

  return (
    <div className={s['filters']}>
      <form onSubmit={handleSubmit(handleSubmitOverviewFilter)}>
        <div className={s['filters-row']}>
          <div className={s['filters-row__title']}>{t('Filter by:')}</div>
          <div className={s['filters-row__battery']}>
            <div className={s['item']}>
              <ReportingSelect
                name="selectedGender"
                options={initialFetchedData?.gendersOptions}
                placeholder={t('Gender')}
                control={control}
                disabled={initialFetchedData?.gendersOptions?.length === 0}
                defaultValue={filterOptions?.overview?.selectedGender}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="selectedDevice"
                options={initialFetchedData?.osOptions}
                placeholder={t('Device')}
                control={control}
                disabled={initialFetchedData?.osOptions?.length === 0}
                defaultValue={filterOptions?.overview?.selectedDevice}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="selectedCity"
                options={initialFetchedData?.citiesOptions}
                placeholder={t('City')}
                control={control}
                disabled={initialFetchedData?.citiesOptions?.length === 0}
                defaultValue={filterOptions?.overview?.selectedCity}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="ageFrom"
                options={initialFetchedData?.agesOptions}
                placeholder={t('Age From')}
                control={control}
                disabled={initialFetchedData?.agesOptions?.length === 0 || watchAllAges}
                defaultValue={filterOptions?.overview?.ageFrom}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="ageTo"
                options={initialFetchedData?.agesOptions}
                placeholder={t('Age To')}
                control={control}
                disabled={initialFetchedData?.agesOptions?.length === 0 || watchAllAges}
                defaultValue={filterOptions?.overview?.ageTo}
              />
            </div>
            <div className={s['item-age__all']}>
              <Controller
                control={control}
                name="allAges"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      label={t('All ages')}
                      name="allAges"
                      value="allAges"
                      checked={value}
                      onChange={(e) => hanldeAllAgesChange(e, onChange)}
                      disabled={initialFetchedData?.agesOptions?.length === 0}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className={s['filters-row']}>
          <div className={s['filters-row__battery']}>
            <div className={s['item-date__section']}>
              <Controller
                name="fromDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className={s['filters__date-from-wrap']}>
                      <DatePicker
                        className="react-datepicker__custom-input-gray"
                        closeOnScroll={true}
                        dateFormat={setInputDateFormat(countryCode, true)}
                        selected={value}
                        selectsStart
                        startDate={value}
                        endDate={watchToDate}
                        onChange={(date) => onChange(new Date(date))}
                        placeholderText={t('Date From')}
                      />
                      <SvgIcon name="date-picker" />
                    </div>
                  );
                }}
              />
              <div className={s['item-to']}>{t('to')}</div>
              <Controller
                name="toDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className={s['filters__date-to-wrap']}>
                      <DatePicker
                        className="react-datepicker__custom-input-gray"
                        closeOnScroll={true}
                        dateFormat={setInputDateFormat(countryCode, true)}
                        selected={value}
                        onChange={(date) => onChange(endOfDay(new Date(date)))}
                        selectsEnd
                        startDate={watchFromDate}
                        minDate={watchFromDate}
                        endDate={value}
                        placeholderText={t('Date To')}
                      />
                      <SvgIcon name="date-picker" />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className={s['filters-row']}>
          <div className={s['buttons']}>
            <div className={s['buttons-filter']}>
              <div className={s['buttons-filter__submit']}>
                <Button
                  type="submit"
                  styling="filter"
                  customText={t('Apply Filters')}
                  disabled={false}
                  fullwidth
                />
              </div>
              <div className={s['link-text']} onClick={resetForm}>
                {t('clear all filters')}
              </div>
            </div>
            {!brandIdFromAdmin && (
              <DownloadCSVButton handleDownloadCSV={handleDownloadCSV} getValues={getValues} />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default OverviewFilters;
