import { useT } from '@transifex/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NestedSummary } from '../NestedSummary';

import s from '../Summary.module.scss';

import classNames from 'classnames/bind';
import { Markup } from 'interweave';
import ReactPlayer from 'react-player';
import QRCode from 'react-qr-code';
import videoCover from '../../../../../../../assets/images/video-cover.png';
import { SvgIcon } from '../../../../../../../components/common/SvgIcon';
import config from '../../../../../../../config';
import { useRouter } from '../../../../../../../hooks/useRouter';
import { isChanged } from '../../../../../../../utils/getSummaryChanges';
import { CpmTable } from './CpmTable';
const cx = classNames.bind(s);

function AdSummary({ ad, adSet, brandInfo, disable }) {
  const t = useT();
  const {
    id,
    adCover,
    adVideo: mediaUri,
    ctaLink,
    ctaDiscount,
    ctaButtonText,
    isUnite,
    videoWidth,
    videoHeight,
    differences,
    thirdPartyTrackingScripts,
    mediaType,
    caption,
  } = ad;
  const { query } = useRouter();

  const [computedHeight, setComputedHeight] = useState('100%');
  const [computedWidth, setComputedWidth] = useState('100%');

  const hasFile = mediaUri?.includes('blob') ?? true;

  const isShowAdCover = useMemo(() => {
    return brandInfo.role === 'WeAre8' && isUnite;
  }, [isUnite, brandInfo.role]);

  const videoContainerRef = useCallback(
    (node) => {
      if (node && videoWidth && videoHeight && !hasFile) {
        const videoRatio = videoWidth / videoHeight;
        const containerRatio = node.clientWidth / node.clientHeight;

        if (videoRatio >= containerRatio) {
          setComputedHeight((node.clientWidth * videoHeight) / videoWidth);
          setComputedWidth('100%');
        } else {
          setComputedHeight(node.clientHeight);
          setComputedWidth((node.clientHeight * videoWidth) / videoHeight);
        }
      }
    },
    [videoWidth, videoHeight, hasFile]
  );

  const videoElementRef = useRef(null);

  const setVideoObjectFit = useCallback(() => {
    videoElementRef.current.getInternalPlayer().style.objectFit = hasFile ? 'contain' : 'cover';
  }, [hasFile]);

  useEffect(() => {
    if (videoElementRef?.current?.getInternalPlayer()) {
      setVideoObjectFit();
    }
  }, [setVideoObjectFit]);

  return (
    <>
      {ad.isCreated && (
        <NestedSummary name={ad.name} iconName="ad">
          <div className={s['ad']}>
            <div className={s['media']}>
              <div className={s['summary__section']}>
                <div className={s['summary__section-content']}>
                  <div className={s['media-wrap']}>
                    {isShowAdCover && (
                      <div className={s['media-wrap__item']}>
                        <div className={s['title']}>{t('Message Cover')}</div>
                        <img className={s['image']} src={adCover || videoCover} alt="ad cover" />
                      </div>
                    )}
                    <div className={s['media-wrap__item']}>
                      {mediaType.value === 'video' ? (
                        <>
                          <div className={s['title']}>
                            {isChanged(differences, 'MediaType') && (
                              <SvgIcon style={{ margin: 'auto' }} name="warning" />
                            )}
                            {t('Video Message')}
                          </div>
                          <div className={s['video-container']} ref={videoContainerRef}>
                            <ReactPlayer
                              className={s['player']}
                              volume={1}
                              controls={true}
                              height={hasFile ? '100%' : computedHeight}
                              width={hasFile ? '100%' : computedWidth}
                              url={mediaUri || ''}
                              ref={videoElementRef}
                              onReady={setVideoObjectFit}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={s['title']}>
                            {isChanged(differences, 'MediaType') && (
                              <SvgIcon style={{ margin: 'auto' }} name="warning" />
                            )}
                            {t('Image Message')}
                          </div>
                          <img className={s['image']} src={mediaUri} alt="ad" />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>
                  {isChanged(differences, 'MediaType') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  {t('Media Type')}
                </div>
                <div className={s['summary__section-content']}>{mediaType.label}</div>

                <div className={s['summary__section-title']} style={{ marginTop: '20px' }}>
                  {t('Pricing')}
                </div>
                <div className={s['summary__section-content']}>
                  <CpmTable mediaType={mediaType.value} />
                </div>
              </div>
            </div>
            {caption?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>
                  {isChanged(differences, 'caption') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  {t('Caption')}
                </div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>
                    {<Markup content={caption.replaceAll('\n', '<br />')} /> || ''}
                  </div>
                </div>
              </div>
            )}

            {ctaLink?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>
                  {isChanged(differences, 'ctaUrl') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  {t('Call To Action Link')}
                </div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>{ctaLink || ''}</div>
                </div>
              </div>
            )}
            {ctaButtonText?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>
                  {isChanged(differences, 'ctaButtonText') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  {`${t('Custom')} CTA ${t('button text')}`}
                </div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>{ctaButtonText || ''}</div>
                </div>
              </div>
            )}
            {ctaDiscount?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Discount Code')}</div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>{ctaDiscount || ''}</div>
                </div>
              </div>
            )}
            {thirdPartyTrackingScripts?.filter(Boolean).length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Third Party Tracking')}</div>
                <div className={s['summary__section-content']}>
                  {thirdPartyTrackingScripts?.map((item) => (
                    <div className={s['summary__section-content-flex']}>
                      <span className={s['accented']} style={{ textTransform: 'capitalize' }}>
                        {item?.provider}
                      </span>
                      <span>{item?.script}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {mediaUri && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Test your Ad!')}</div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>
                    {t(
                      'Click the link below to preview how people will see your Ad! You can also scan the QR code to open the preview on your mobile device! Completions of the test Ad will not be recorded nor charged for.'
                    )}
                    <div className={s['popup-hyperlink-container']}>
                      <a
                        className={cx(s['popup-hyperlink'], s[disable ? 'disabled' : ''])}
                        href={`${config.pwaDomain}/basic-ads/preview/${id}?brandId=${brandInfo.id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {t('Test on PC')}
                      </a>
                      {t(' or')}
                      <div className={s['qrCode-container']}>
                        <QRCode
                          title="Test on mobile"
                          size={80}
                          value={`${config.pwaDomain}/basic-ads/preview/${id}?brandId=${brandInfo.id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </NestedSummary>
      )}
    </>
  );
}

export default AdSummary;
