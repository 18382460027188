import { useT } from '@transifex/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NestedSummary } from '../NestedSummary';

import s from '../Summary.module.scss';

import ReactPlayer from 'react-player';
import QRCode from 'react-qr-code';
import videoCover from '../../../../../assets/images/video-cover.png';
import config from '../../../../../config';
import { isChanged } from '../../../../../utils/getSummaryChanges';
import { SvgIcon } from '../../../../common/SvgIcon';
import classNames from 'classnames/bind';

const cx = classNames.bind(s);

function AdSummary({ ad, adSet, brandInfo, disable }) {
  const t = useT();
  const {
    id,
    adCover,
    adVideo,
    ctaCover,
    ctaLink,
    ctaDiscount,
    ctaButtonText,
    questions,
    smsMessage,
    isUnite,
    videoWidth,
    videoHeight,
    differences,
    thirdPartyTrackingScripts,
  } = ad;

  const [computedHeight, setComputedHeight] = useState('100%');
  const [computedWidth, setComputedWidth] = useState('100%');

  const hasFile = adVideo?.includes('blob') ?? true;

  const isShowAdCover = useMemo(() => {
    return brandInfo.role === 'WeAre8' && isUnite;
  }, [isUnite, brandInfo.role]);

  const videoContainerRef = useCallback(
    (node) => {
      if (node && videoWidth && videoHeight && !hasFile) {
        const videoRatio = videoWidth / videoHeight;
        const containerRatio = node.clientWidth / node.clientHeight;

        if (videoRatio >= containerRatio) {
          setComputedHeight((node.clientWidth * videoHeight) / videoWidth);
          setComputedWidth('100%');
        } else {
          setComputedHeight(node.clientHeight);
          setComputedWidth((node.clientHeight * videoWidth) / videoHeight);
        }
      }
    },
    [videoWidth, videoHeight, hasFile]
  );

  const videoElementRef = useRef(null);

  const setVideoObjectFit = useCallback(() => {
    videoElementRef.current.getInternalPlayer().style.objectFit = hasFile ? 'contain' : 'cover';
  }, [hasFile]);

  useEffect(() => {
    if (videoElementRef?.current?.getInternalPlayer()) {
      setVideoObjectFit();
    }
  }, [setVideoObjectFit]);

  return (
    <>
      {ad.isCreated && (
        <NestedSummary name={ad.name} iconName="ad">
          <div className={s['ad']}>
            {smsMessage?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('SMS Message')}</div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>{smsMessage || ''}</div>
                </div>
              </div>
            )}
            {questions?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Questions')}</div>
                <div className={s['summary__section-content']}>
                  {questions.map((question, index) => (
                    <div
                      key={question.questionOrder}
                      className={s['summary__section-content-block']}
                    >
                      <span className={s['accented']}>{`${index + 1}. ${
                        question.type.label
                      } - `}</span>
                      {question.text}
                      {question.options.length
                        ? question.options.map((option, index) => (
                            <div key={index} className={s['option']}>
                              - {option.text}
                            </div>
                          ))
                        : ''}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className={s['media']}>
              <div className={s['summary__section']}>
                <div className={s['summary__section-content']}>
                  <div className={s['media-wrap']}>
                    {isShowAdCover && (
                      <div className={s['media-wrap__item']}>
                        <div className={s['title']}>{t('Message Cover')}</div>
                        <img className={s['image']} src={adCover || videoCover} alt="ad cover" />
                      </div>
                    )}
                    <div className={s['media-wrap__item']}>
                      <div className={s['title']}>{t('Video Message')}</div>
                      <div className={s['video-container']} ref={videoContainerRef}>
                        <ReactPlayer
                          className={s['player']}
                          volume={1}
                          controls={true}
                          height={hasFile ? '100%' : computedHeight}
                          width={hasFile ? '100%' : computedWidth}
                          url={adVideo || ''}
                          ref={videoElementRef}
                          onReady={setVideoObjectFit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={s['summary__section']}>
                <div className={s['summary__section-content']}>
                  <div className={s['media-wrap']}>
                    <div className={s['cta-image']}>
                      <div className={s['media-wrap__item']}>
                        <div className={s['title']}>
                          {isChanged(differences, 'CtaImage') && (
                            <SvgIcon style={{ margin: 'auto' }} name="warning" />
                          )}
                          {t('Call To Action Tile')}
                        </div>
                        <img className={s['image']} src={ctaCover || ''} alt="cta cover" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {ctaLink?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>
                  {isChanged(differences, 'CtaWebLink') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  {t('Call To Action Link')}
                </div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>{ctaLink || ''}</div>
                </div>
              </div>
            )}
            {ctaButtonText?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>
                  {`${t('Custom')} CTA ${t('button text')}`}
                </div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>{ctaButtonText || ''}</div>
                </div>
              </div>
            )}
            {ctaDiscount?.length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Discount Code')}</div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>{ctaDiscount || ''}</div>
                </div>
              </div>
            )}
            {thirdPartyTrackingScripts?.filter(Boolean).length > 0 && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Third Party Tracking')}</div>
                <div className={s['summary__section-content']}>
                  {thirdPartyTrackingScripts?.map((item) => (
                    <div className={s['summary__section-content-flex']}>
                      <span className={s['accented']} style={{ textTransform: 'capitalize' }}>
                        {item?.provider}
                      </span>
                      <span>{item?.script}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {adVideo && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Test your Ad!')}</div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-block']}>
                    {t(
                      'Click the link below to preview how people will see your Ad! You can also scan the QR code to open the preview on your mobile device! Completions of the test Ad will not be recorded nor charged for.'
                    )}
                    <div className={s['popup-hyperlink-container']}>
                      <a
                        className={cx(s['popup-hyperlink'], s[disable ? 'disabled' : ''])}
                        href={`${config.pwaDomain}/preview/${id}?brandId=${brandInfo.id}&countryCode=${brandInfo.countryCode}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {t('Test on PC')}
                      </a>
                      {t(' or')}
                      <div className={s['qrCode-container']}>
                        <QRCode
                          title="Test on mobile"
                          size={80}
                          value={`${config.pwaDomain}/preview/${id}?brandId=${brandInfo.id}&countryCode=${brandInfo.countryCode}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </NestedSummary>
      )}
    </>
  );
}

export default AdSummary;
