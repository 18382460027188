import { useEffect, useState } from 'react';
import { useLanguages } from '@transifex/react';
import { LOCALE_CHANGED, offEvent, onEvent, tx } from '@transifex/native';

export const isListEmpty = (list) => !Array.isArray(list) || list.length === 0;

function useLanguage() {
  const languages = useLanguages();
  const [isLocalizationLoading, setIsLocalizationLoading] = useState(true);
  const [isUsersSelectedLanguageSupported, setIsUsersSelectedLanguageSupported] = useState(true);

  useEffect(() => {
    onEvent(LOCALE_CHANGED, () => setIsLocalizationLoading(false));

    return () => {
      offEvent(LOCALE_CHANGED, () => setIsLocalizationLoading(false));
    };
  }, [isUsersSelectedLanguageSupported, languages]);

  useEffect(() => {
    const selectUsersLanguage = (languages) => {
      const language = window.navigator.language;
      const languageCountryCode = language.split('-')[0];

      const languageIsSupported = languages.some(
        (language) => language.code === languageCountryCode
      );

      setIsUsersSelectedLanguageSupported(languageIsSupported);

      return languageIsSupported ? languageCountryCode : 'en';
    };

    if (!isListEmpty(languages)) {
      const languageToDisplay = selectUsersLanguage(languages);
      tx.setCurrentLocale(languageToDisplay);
    }
  }, [languages]);

  return {
    isLocalizationLoading,
  };
}

export default useLanguage;
