import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useIdentifyCampaignType = () => {
  const location = useLocation();

  const isBasicAd = useMemo(() => {
    const url = location.pathname + location.search;
    return url.includes('basic-ad') || url.includes('basic-campaign');
  }, [location]);

  return { isBasicAd };
};

export default useIdentifyCampaignType;
