import { createAction, createAsyncAction } from 'eight.js.store-common';

export const demographicsFilterRequest = createAction(
  '@@basicAdAnalytics/DEMOGRAPHICS_FILTER_REQUEST'
);

export const fetchAcceptanceOverTimeAsync = createAsyncAction(
  '@@basicAdAnalytics/FETCH_ACCEPTANCE_OVER_TIME'
);

export const fetchFilteredAcceptanceOverTimeAsync = createAsyncAction(
  '@@basicAdAnalytics/FETCH_ACCEPTANCE_OVER_TIME_FILTERED'
);

export const fetchAnalyticsCampaignAsync = createAsyncAction(
  '@@basicAdAnalytics/FETCH_ANALYTICS_CAMPAIGN'
);

export const fetchAnalyticsSponsorshipAsync = createAsyncAction(
  '@@basicAdAnalytics/FETCH_ANALYTICS_SPONSORSHIP'
);

export const fetchAnalyticsSponsorshipSetAsync = createAsyncAction(
  '@@basicAdAnalytics/FETCH_ANALYTICS_SPONSORSHIP_SET'
);

export const fetchDemographicsAsync = createAsyncAction('@@basicAdAnalytics/FETCH_DEMOGRAPHICS');

export const fetchFilteredDemographicsAsync = createAsyncAction(
  '@@basicAdAnalytics/FETCH_DEMOGRAPHICS_FILTERED'
);

export const fetchHeatMapAsync = createAsyncAction('@@basicAdAnalytics/FETCH_HEAT_MAP');

export const fetchTotalsAsync = createAsyncAction('@@basicAdAnalytics/FETCH_TOTALS');

export const fetchFilteredTotalsAsync = createAsyncAction(
  '@@basicAdAnalytics/FETCH_TOTALS_FILTERED'
);

export const overviewFilterRequest = createAction('@@basicAdAnalytics/OVERVIEW_FILTER_REQUEST');

export const setOverviewFilterOption = createAction(
  '@@basicAdAnalytics/SET_OVERVIEW_FILTER_OPTION'
);

export const setInitialFetchedData = createAction('@@basicAdAnalytics/SET_INITIAL_FETCHED_DATA');

export const setDemographicsFilterOption = createAction(
  '@@basicAdAnalytics/SET_DEMOGRAPHIC_FILTER_OPTION'
);

export const getCSVDataAsync = createAsyncAction('@@basicAdAnalytics/GET_CSV_DATA');

export const toggleIsLoadingCSVData = createAction('@@basicAdAnalytics/TOGGLE_IS_LOADING_CSV_DATA');

export const updateDownloadPercentage = createAction(
  '@@basicAdAnalytics/UPDATE_DOWNLOAD_PERCENTAGE'
);

export const setCSVDataName = createAction('@@basicAdAnalytics/SET_CSV_DATA_NAME');

export const setCSVDataDefault = createAction('@@basicAdAnalytics/SET_CSV_DATA_DEFAULT');

export const clearCSVDataForDownload = createAction(
  '@@basicAdAnalytics/CLEAR_CSV_DATA_FOR_DOWNLOAD'
);

export const setCSVRequestId = createAction('@@basicAdAnalytics/SET_CSV_REQUEST_ID');

export const checkForCSVCompletionAsync = createAsyncAction(
  '@@basicAdAnalytics/CHECK_FOR_CSV_COMPLETION'
);

export const createPerformanceReportingCSVAsync = createAsyncAction(
  '@@basicAdAnalytics/CREATE_PERFORMANCE_REPORTING_CSV'
);
