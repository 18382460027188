import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { Logo } from '../../../components/Logo';
import { MainMenu } from '../../../components/Menus/MainMenu';
import { GeneralSidebar } from '../../../components/Sidebars/GeneralSidebar';

import { Home } from '../../Home';
import { Reporting } from '../../Reporting';
import { ReportingBasicAds } from '../../ReportingBasicAds';

import { modalIsOpenSelector, sidebarOpenSelector, toggleSideBar } from '../../../store/ui';

import { AltPageWrapper } from '../../../components/wrappers/AltPageWrapper';
import { mainMenu, routes } from '../../../constants';
import { CampaignNotFound } from '../../CampaignNotFound';
import ManageBasicAds from '../../ManageBasicAds';
import { ManagePremiumAds } from '../../ManagePremiumAds';
import { NotFound } from '../../NotFound';

const WithSidebarContainer = () => {
  const isOpen = useSelector(sidebarOpenSelector);
  const modalIsOpen = useSelector(modalIsOpenSelector);
  const dispatch = useDispatch();
  return (
    <>
      <GeneralSidebar
        modalIsOpen={modalIsOpen}
        isOpen={isOpen}
        toggleSidebar={() => dispatch(toggleSideBar())}
      >
        <Logo isOpen={isOpen} />
        <MainMenu isOpen={isOpen} items={mainMenu.items} />
      </GeneralSidebar>
      <AltPageWrapper isOpen={isOpen}>
        <Switch>
          <Route exact path={routes.HOME} component={Home} />
          <Route exact path={routes.MANAGE_PREMIUM_ADS} component={ManagePremiumAds} />
          <Route exact path={routes.BASIC_AD.MANAGE} component={ManageBasicAds} />
          <Route exact path={`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}`} component={Reporting} />
          <Route
            exact
            path={`${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}`}
            component={ReportingBasicAds}
          />
          <Route
            exact
            path={`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}`}
            component={Reporting}
          />
          <Route
            exact
            path={`${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}${routes.BASIC_AD.SPONSORSHIP_SET_ROUTE}`}
            component={ReportingBasicAds}
          />
          <Route
            path={`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}${routes.SPONSORSHIP_ROUTE}`}
            component={Reporting}
          />
          <Route
            path={`${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}${routes.BASIC_AD.SPONSORSHIP_ROUTE}`}
            component={ReportingBasicAds}
          />
          <Route path={routes.CAMPAIGN_NOT_FOUND} component={CampaignNotFound} />
          <Route path={routes.NOT_FOUND} component={NotFound} />
          <Redirect to={routes.NOT_FOUND} />
        </Switch>
      </AltPageWrapper>
    </>
  );
};

export default WithSidebarContainer;
