import React, { useEffect } from 'react';

import { ReportingChart } from '../../ReportingChart';
import { ReportingOverviewTotal } from './../ReportingOverviewTotal';

import { Donut } from '../../../../Charts/Donut';
import { Line } from '../../../../Charts/Line';
import { Map } from '../../../../Charts/Map';

import s from './ReportingOverviewContent.module.scss';
import { FeatureFlag } from '../../../../common/FeatureFlag';
import { numberToCurrency } from '../../../../../utils/budget';
import { useT } from '@transifex/react';

const ReportingOverviewContent = ({
  sponsorshipSet,
  influencersByGender,
  acceptanceOverTime,
  brandInfo,
  totals,
  handleDownloadCSV,
  clickThroughPercentage,
  country,
  combinedAndOrderedResponses,
  getChartForQuestion,
  heatMap,
  setOverviewNode,
  setResponsesNode,
  getCPCV,
  getSpent,
  data,
  conversionEventNames,
}) => {
  // Getting section nodes for image captures
  const node = document.getElementById('reporting-overview-section');
  const node2 = document.getElementById('reporting-responses-section');
  const t = useT();

  useEffect(() => {
    setOverviewNode(node);
    setResponsesNode(node2);
  }, [node, node2, setOverviewNode, setResponsesNode]);

  return (
    <>
      <div id="reporting-overview-section">
        <div className={s['overview-content']}>
          <div className={s['overview-content__grid--small']}>
            <div className={s['grid-block']}>
              <ReportingOverviewTotal
                csvDataType="viewedInFeed"
                handleDownloadCSV={handleDownloadCSV}
                downloadable={false}
                disabled={!totals?.viewed_in_feed?.total}
                title={t('Impressions')}
                number={totals?.viewed_in_feed?.total}
                counters={[
                  {
                    title: t('Last 7 Days'),
                    number: totals?.viewed_in_feed?.last7Days,
                  },
                  {
                    title: t('Last 30 Days'),
                    number: totals?.viewed_in_feed?.last30Days,
                  },
                ]}
              />
            </div>
            <div className={s['grid-block']}>
              <ReportingOverviewTotal
                csvDataType="completions"
                handleDownloadCSV={handleDownloadCSV}
                downloadable={false}
                disabled={!totals?.accepted?.total}
                title={t('Completions ')}
                number={totals?.accepted?.total}
                counters={[
                  {
                    title: t('Last 7 Days'),
                    number: totals?.accepted?.last7Days,
                  },
                  {
                    title: t('Last 30 Days'),
                    number: totals?.accepted?.last30Days,
                  },
                ]}
              />
            </div>
            <div className={s['grid-block']}>
              <ReportingOverviewTotal
                csvDataType="videoViewed"
                handleDownloadCSV={handleDownloadCSV}
                downloadable={false}
                disabled={!totals?.video_viewed?.total}
                title={t('Completed Video Views')}
                number={totals?.video_viewed?.total}
                counters={[
                  {
                    title: t('Last 7 Days'),
                    number: totals?.video_viewed?.last7Days,
                  },
                  {
                    title: t('Last 30 Days'),
                    number: totals?.video_viewed?.last30Days,
                  },
                ]}
              />
            </div>
          </div>
          <div className={s['overview-content__grid--medium']}>
            <div className={s['grid-block']}>
              <ReportingOverviewTotal countup={false} title="CPM" number={getCPCV} />
            </div>
            <div className={s['grid-block']}>
              <ReportingOverviewTotal countup={false} title={t('Spent')} number={getSpent} />
            </div>
          </div>
          <div className={s['overview-content__grid--medium']}>
            <div className={s['grid-block']}>
              <ReportingOverviewTotal
                csvDataType="clickThrough"
                handleDownloadCSV={handleDownloadCSV}
                downloadable={false}
                disabled={!totals?.click_through?.total}
                title={t('Click Through')}
                number={totals?.click_through?.total}
                percentage={clickThroughPercentage}
                counters={[
                  {
                    title: t('Last 7 Days'),
                    number: totals?.click_through?.last7Days,
                  },
                  {
                    title: t('Last 30 Days'),
                    number: totals?.click_through?.last30Days,
                  },
                  {
                    title: 'Avg. CPC',
                    number: totals?.click_through?.avg
                      ? numberToCurrency(totals?.click_through?.avg)
                      : '--',
                  },
                ]}
              />
            </div>
            <FeatureFlag name="WA8-2359">
              <div className={s['grid-block']}>
                <ReportingOverviewTotal
                  title={t('Frequency')}
                  disabled={!totals?.sponsorship_frequency?.total}
                  number={totals?.sponsorship_frequency?.total}
                  counters={[
                    {
                      title: t('Last 7 Days'),
                      number: totals?.sponsorship_frequency?.last7Days,
                    },
                    {
                      title: t('Last 30 Days'),
                      number: totals?.sponsorship_frequency?.last30Days,
                    },
                  ]}
                />
              </div>
            </FeatureFlag>
          </div>
          <div className={s['overview-content__flex--large']}>
            <div className={s['grid-block']}>
              <ReportingOverviewTotal
                csvDataType="conversions"
                handleDownloadCSV={handleDownloadCSV}
                downloadable={false}
                disabled={!totals?.conversions?.total}
                title={t('Conversions')}
                number={totals?.conversions?.total}
                percentage={(totals?.conversions?.total * 100).toFixed(0)}
                counters={
                  sponsorshipSet.pixelConversionEvent !== 'unknown'
                    ? [
                        {
                          title: t('Last 7 Days'),
                          number: totals?.conversions?.last7Days,
                        },
                        {
                          title: t('Last 30 Days'),
                          number: totals?.conversions?.last30Days,
                        },
                        {
                          title: 'Avg. Cpa',
                          number: totals?.costPerAction?.total
                            ? numberToCurrency(totals?.costPerAction?.total)
                            : '--',
                        },
                        { title: 'Event Type', number: conversionEventNames },
                      ]
                    : [
                        {
                          title: t('Last 7 Days'),
                          number: totals?.conversions?.last7Days,
                        },
                        {
                          title: t('Last 30 Days'),
                          number: totals?.conversions?.last30Days,
                        },
                        {
                          title: 'Avg. Cpa',
                          number: totals?.costPerAction?.total
                            ? numberToCurrency(totals?.costPerAction?.total)
                            : '--',
                        },
                      ]
                }
              />
            </div>
          </div>
        </div>
      </div>
      {data.responses && (
        <>
          <div id="reporting-responses-section">
            <div className={s['overview-content']}>
              <div className={s['overview-content__flex--medium']}>
                {combinedAndOrderedResponses &&
                  combinedAndOrderedResponses.map((question, questionKey) => (
                    <div key={questionKey} className={s['question-item']}>
                      <ReportingChart
                        csvDataType={question?.metadata?.questionKind}
                        handleDownloadCSV={handleDownloadCSV}
                        downloadable={question?.metadata?.questionKind === 'ShortAnswer'}
                        question={{
                          id: question?.metadata?.questionId,
                          number: questionKey + 1,
                          text: question?.metadata?.questionText,
                        }}
                        chartStyle={{ alignItems: 'flex-start' }}
                      >
                        {question?.metadata?.questionKind !== 'ShortAnswer' &&
                          getChartForQuestion(question)}
                      </ReportingChart>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      <div className={s['overview-content__flex--large']}>
        <div className={s['block-large']}>
          <ReportingChart
            csvDataType="userEngagement"
            handleDownloadCSV={handleDownloadCSV}
            downloadable={false}
            title={t('User Engagement')}
          >
            {acceptanceOverTime && (
              <Line acceptanceOverTime={acceptanceOverTime} countryCode={brandInfo.countryCode} />
            )}
          </ReportingChart>
        </div>
      </div>

      <div className={s['overview-content__flex--medium']}>
        <div className={s['flex-item']}>
          <ReportingChart title={t('By Location')} withLink>
            {heatMap?.data?.length > 0 ? (
              <Map country={country} joinBy={'hc-a2'} series={heatMap.data} mapWithCities />
            ) : (
              <div className={s['no-data']}>{t('No data available')}</div>
            )}
          </ReportingChart>
        </div>
        <div className={s['flex-item']}>
          <ReportingChart title={t('By Gender')} titleStyle={{ marginBottom: 0 }} withLink>
            {influencersByGender?.data?.length > 0 ? (
              <Donut dataType="gender" series={influencersByGender} />
            ) : (
              <div className={s['no-data']}>{t('No data available')}</div>
            )}
          </ReportingChart>
        </div>
      </div>

      {/* <h1 className={s['overview-header']}>Ad & Incentive Overview</h1>

      <div className={s['overview-content']}>
        <div className={s['overview-content__media']}>
          <div className={s['media-item__video']}>
            <div className={s['title']}>Content</div>
            <ReactPlayer
              className={s['player']}
              volume={1}
              controls={true}
              height="100%"
              width="100%"
              url={sponsorship?.adVideo || ''}
            />
          </div>
          {sponsorship.adCover && (
            <div className={s['media-item__cta']}>
              <div className={s['title']}>CTA Tile</div>
              <div className={s['img__wrap']}>
                <img alt="Sponsorship CTA Tile" src={sponsorship?.adCover} className={s['img']} />
              </div>
            </div>
          )}
        </div>
        <div className={s['overview-content__details']}>
          <div className={s['logo-container']}>
            <div className={s['logo-container__picture']}>
              <img alt="Brand Logo" className={s['img']} src={brandInfo?.logoUri} />
            </div>
            <div className={s['logo-container__name']}>{brandInfo?.brandName}</div>
          </div>
          <div className={s['overview-content__grid-overall']}>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail
                icon="link"
                title="Link to product"
                text={sponsorship?.ctaLink}
                isLongText
              />
            </div>
            {sponsorship.ctaButtonText && (
              <div className={s['grid-item']}>
                <ReportingOverviewDetail
                  // icon="text"
                  title="Link button text"
                  text={sponsorship?.ctaButtonText}
                />
              </div>
            )}
            {sponsorship.ctaDiscount && (
              <div className={s['grid-item']}>
                <ReportingOverviewDetail
                  icon="percentage"
                  title="Discount code"
                  text={sponsorship?.ctaDiscount}
                />
              </div>
            )}
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="medal" title="Incentive" text={incentiveText} />
            </div>
          </div>

          <h1 className={s['overview-header']}>Targeting Details</h1>

          <div className={s['overview-content__grid-targeting']}>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="person" title="Age" text={ageText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="gender" title="Gender" text={genderText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="money" title="Budget" text={budgetText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="calendar" title="Schedule" text={scheduleText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="mobile" title="OS" text={osText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="pin" title="Location(s)" text={locationText} />
            </div>
            {sponsorshipSet?.interests?.length > 0 && (
              <div className={s['grid-item']}>
                <ReportingOverviewDetail icon="tag" title="Custom audiences" text={interestsText} />
              </div>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ReportingOverviewContent;
