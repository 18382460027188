const routes = Object.freeze({
  HOME: '/',
  CAMPAIGN_CREATE: '/campaign/new',
  CAMPAIGN_EDIT: '/campaign/:cid',
  CAMPAIGN_SUMMARY: '/campaign/:cid/summary',
  SPONSORSHIP_SET_EDIT: '/campaign/:cid/adset/:ssid',
  SPONSORSHIP_EDIT: '/campaign/:cid/adset/:ssid/ad/:sid',
  MANAGE_PREMIUM_ADS: '/manage',
  BASIC_AD: {
    MANAGE: '/manage/basic-ads',
    CREATE_CAMPAIGN: '/basic-ad/new',
    EDIT_CAMPAIGN: '/basic-ad/:cid',
    SPONSORSHIP_SET_CREATE: '/basic-ad/:cid/adset/new',
    SPONSORSHIP_SET_CREATE_MULTI: '/basic-ad/:cid/adset/new/:newIndex',
    SPONSORSHIP_SET_EDIT: '/basic-ad/:cid/adset/:ssid',
    SPONSORSHIP_CREATE: '/basic-ad/:cid/adset/:ssid/new',
    SPONSORSHIP_CREATE_MULTI: '/basic-ad/:cid/adset/:ssid/new/:newIndex',
    SPONSORSHIP_EDIT: '/basic-ad/:cid/adset/:ssid/ad/:sid',
    CAMPAIGN_SUMMARY: '/basic-ad/:cid/summary',
    CAMPAIGN_ROUTE: '/basic-campaign/:cid',
    SPONSORSHIP_SET_ROUTE: '/adset/:ssid',
    SPONSORSHIP_ROUTE: '/ad/:sid',
  },
  ADMIN_REPORTING: '/admin/reporting/campaign/:cid',
  REPORTING: '/reporting',
  CAMPAIGN_ROUTE: '/campaign/:cid',
  SPONSORSHIP_SET_ROUTE: '/adset/:ssid',
  SPONSORSHIP_ROUTE: '/ad/:sid',
  REPORTING_DEMOGRAPHICS: '/demographics',
  REPORTING_OVERVIEW: '/overview',
  SETTINGS: '/settings',
  SETTINGS_ACCOUNT_INFO: '/settings/account-info',
  SETTINGS_PASSWORD: '/settings/password',
  SETTINGS_BILLING: '/settings/billing',
  SETTINGS_BILLING_TRANSACTIONS_HISTORY: '/transactions-history',
  SETTINGS_BILLING_REPORT: '/report/:year/:month',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_PIXEL: '/settings/8pixel-info',
  SETTINGS_PIXEL_SETUP: '/settings/8pixel-setup',
  SETTINGS_BRAND_ONBOARDING: '/settings/brand-onboarding',
  SETTINGS_CONTACT_SUPPORT: '/settings/contact-support',
  LOGIN: '/login',
  SIGN_UP: '/signup',
  RESET_PASSWORD_EMAIL: '/reset-password/email',
  RESET_PASSWORD_EMAIL_SENT: '/reset-password/email-sent',
  RESET_PASSWORD_RESET: '/reset-password/reset',
  RESET_PASSWORD_SUCCESS: '/reset-password/success',
  NOT_FOUND: '/404',
  CAMPAIGN_NOT_FOUND: '/campaign-not-found',
  STRIPE: '/settings/billing/stripe/bacs',
  STRIPE_CANCEL: '/settings/billing/stripe/bacs/cancel',
  STRIPE_SUCCESS: '/settings/billing/stripe/bacs/success',
  TRACKING_TEST: '/tracking-test',
  PIXEL_LANDING: '/pixel-landing',
  PIXEL_LANDING_TEST: '/pixel-landing-test',
  PIXEL_PURCHASE: '/pixel-purchase',
  PIXEL_PURCHASE_TEST: '/pixel-purchase-test',
  PIXEL_SUBSCRIPTION: '/pixel-subscription',
  PIXEL_SUBSCRIPTION_TEST: '/pixel-subscription-test',
});

export default routes;
