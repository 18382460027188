import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { isNumber } from 'lodash';
import { useT } from '@transifex/react';

import { FileSpecifications } from '../../../common/FileSpecifications';
import { UploadZone } from '../../../common/UploadZone';

import { FormGroup } from '../../../FormElements/FormGroup';
import { TextInput } from '../../../FormElements/TextInput';

import { ModalCrop } from '../../../ModalCrop';

import { sponsorshipConstants } from '../../../../constants';

import { base64StringToFile, extractImageFileExtension } from '../../../../utils/media';

import s from './AdCallToAction.module.scss';

import { constructTestId } from '../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';

import { useDispatch } from 'react-redux';
import { useScroll } from '../../../../hooks/useScroll';
import ThirdParty from '../../../ThirdParty/ThirdParty';
import { Button } from '../../../common/Button';
import { useFieldArray } from 'react-hook-form';
import { ErrorMessage } from '../../../common/ErrorMessage';
import useIdentifyCampaignType from '../../../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';

const SECTION = CREATE_CAMPAIGN_PAGE.AD_CALL_TO_ACTION;

const AdCallToAction = ({
  brand,
  handleSelect,
  modals,
  sponsorshipCreate,
  toggleModalByName,
  setStickyMenuItem,
  register,
  unregister,
  control,
  errors,
  getValues,
  setValue,
  adLockedWithDeliveryStatus,
  adIsLocked,
}) => {
  const dispatch = useDispatch();
  const {
    fields: thirdPartyTrackingScripts,
    append,
    remove,
  } = useFieldArray({
    name: 'thirdPartyTracking',
    control,
  });
  const [processingCtaCover, setProcessingCtaCover] = useState('');
  const [cropModalIsOpen, setCropModalIsOpen] = useState(false);
  const { isBasicAd } = useIdentifyCampaignType();

  const watchCtaLink = useWatch({ control, name: 'ctaLink' });
  const watchCtaDiscount = useWatch({ control, name: 'ctaDiscount' });
  const watchCtaButtonText = useWatch({ control, name: 'ctaButtonText' });

  const actionRef = useRef(null);

  useScroll([actionRef], 0.2);

  useEffect(() => {
    dispatch(setStickyMenuItem('action'));
  }, [setStickyMenuItem, dispatch]);

  const t = useT();

  const cropperRef = useRef(null);

  const handleChangeCtaCover = useCallback((acceptedFiles) => {
    const ctaCover = URL.createObjectURL(acceptedFiles[0]);

    setProcessingCtaCover(ctaCover);

    setCropModalIsOpen(true);
  }, []);

  const handleCropImage = useCallback(() => {
    const cropper = cropperRef?.current.cropper;

    const cropperData = cropper
      .getCroppedCanvas({
        maxWidth: 2048,
        maxHeight: 2048,
        imageSmoothingQuality: 'high',
      })
      .toDataURL();
    const fileExtension = extractImageFileExtension(cropperData);
    const fileName = `ctaCover${sponsorshipCreate.name}-cropped.${fileExtension}`;
    const croppedFile = base64StringToFile(cropperData, fileName);
    const croppedUrl = URL.createObjectURL(croppedFile);

    setValue('ctaCoverFile', croppedFile);
    setValue('ctaCover', croppedUrl);

    setCropModalIsOpen(false);
  }, [cropperRef, sponsorshipCreate.name, setValue]);

  const handleCloseCropModal = useCallback(() => {
    setCropModalIsOpen(false);
  }, []);

  const handleAddThirdParty = useCallback(() => {
    const newScript = {
      provider: '',
      script: '',
    };
    append(newScript);
  }, [append]);
  return (
    <>
      <div id="action" ref={actionRef} className={s['action']}>
        <div className={s['action-inner']}>
          {!isBasicAd && (
            <FormGroup
              title={t(`Upload ‘Call To Action' Image`)}
              subtitle={t(
                'This is what consumers can click on to learn more or take action. If you do not have an image, please upload your logo.'
              )}
              required
            >
              <FileSpecifications fileSpecs={sponsorshipConstants.ctaImageFileSpecs} />

              <div className={s['action-inner__cta']}>
                <input
                  type="hidden"
                  value={
                    isNumber(sponsorshipCreate.ctaCoverFile.size)
                      ? sponsorshipCreate.ctaCoverFile.size
                      : 0
                  }
                  name="ctaCoverFile"
                  aria-hidden="true"
                />
                <Controller
                  control={control}
                  name="ctaCover"
                  render={({ field: { value, ref } }) => {
                    return (
                      <UploadZone
                        inputRef={ref}
                        data-testid={constructTestId(SECTION, 'img-upload')}
                        styleType="square"
                        name="ctaCover"
                        type="image"
                        control={control}
                        error={errors.ctaCover}
                        handleChange={handleChangeCtaCover}
                        contentType="image/jpeg, image/png"
                        fileUrl={value}
                      />
                    );
                  }}
                />

                {/*<div className={s['action-inner__cta-img']}>*/}
                {/*  <img*/}
                {/*    className={s['cta-image']}*/}
                {/*    src={`${process.env.PUBLIC_URL}/images/cta-cover.png`}*/}
                {/*    alt='cta-sample'*/}
                {/*  />*/}
                {/*  <div className={s['action-inner__cta-text']}>*/}
                {/*    Example CTA Image*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </FormGroup>
          )}

          <div className={s['action-inner__link']}>
            <FormGroup
              title={t(`‘Call To Action' Link`)}
              subtitle={t(`After people watch your ad, where would you like to direct them?`)}
              required
            >
              <TextInput
                data-testid={constructTestId(SECTION, 'link-input')}
                type="text"
                id="ctaLink"
                name="ctaLink"
                label={t('CTA URL')}
                register={register}
                watchValue={watchCtaLink}
                disabled={
                  isBasicAd &&
                  (adLockedWithDeliveryStatus.review || adLockedWithDeliveryStatus.live)
                }
                error={errors.ctaLink}
              />
            </FormGroup>
          </div>

          <div className={s['action-inner__buttonText']}>
            <FormGroup
              title={t(`Custom CTA button text`)}
              subtitle={t(`Optionally, you may customize the CTA button text. The default text is 
              ‘Check it Out', but you can specify: ‘Find out more', ‘Buy Now', ‘Download 
              Now' etc.`)}
              required={isBasicAd}
            >
              <TextInput
                data-testid={constructTestId(SECTION, 'button-text-input')}
                type="text"
                id="ctaButtonText"
                name="ctaButtonText"
                label={t('CHECK IT OUT')}
                watchValue={watchCtaButtonText}
                register={register}
                error={errors.ctaButtonText}
                disabled={
                  isBasicAd
                    ? adLockedWithDeliveryStatus.review || adLockedWithDeliveryStatus.live
                    : adIsLocked
                }
              />
            </FormGroup>
          </div>

          {!isBasicAd && (
            <div className={s['action-inner__discount']}>
              <FormGroup
                title={t('Discount Code (Optional)')}
                subtitle={t(
                  'Optionally, you may provide your participants with a discount code toward their next purchase.'
                )}
              >
                <TextInput
                  data-testid={constructTestId(SECTION, 'discount-code-input')}
                  type="text"
                  id="ctaDiscount"
                  name="ctaDiscount"
                  label={t('DISCOUNT CODE')}
                  watchValue={watchCtaDiscount}
                  register={register}
                  error={errors.ctaDiscount}
                  disabled={adIsLocked}
                />
              </FormGroup>
            </div>
          )}

          <div className={s['action-inner__thirdPartyTracking']}>
            <FormGroup
              title={t('Third Party Tracking (Optional)')}
              subtitle={t(
                'Feel free to use a 3rd party tracking tool to verify the completions! Insert the 1x1 tracking JavaScript code below and it will be added to the completion page.'
              )}
            >
              {thirdPartyTrackingScripts.map((item, index) => {
                return (
                  <ThirdParty
                    key={item.id}
                    control={control}
                    register={register}
                    adIsLocked={
                      isBasicAd
                        ? adLockedWithDeliveryStatus.review || adLockedWithDeliveryStatus.live
                        : adIsLocked
                    }
                    section={SECTION}
                    sponsorshipCreate={sponsorshipCreate}
                    withDeleteButton={true}
                    onDelete={() => remove(index)}
                    errors={errors}
                    index={index}
                    setValue={setValue}
                  />
                );
              })}
              {errors.thirdPartyTracking && (
                <ErrorMessage error={errors.thirdPartyTracking.message} />
              )}
              <div className={s['button-container']}>
                <Button
                  styling="black"
                  type="button"
                  callback={handleAddThirdParty}
                  name={t('Add 3rd Party')}
                  disabled={
                    isBasicAd
                      ? adLockedWithDeliveryStatus.review || adLockedWithDeliveryStatus.live
                      : adIsLocked
                  }
                  data-testid={constructTestId(SECTION, 'add-thirdParty')}
                />
              </div>
            </FormGroup>
          </div>
        </div>
      </div>

      {cropModalIsOpen && (
        <ModalCrop
          imageUri={processingCtaCover}
          cropperRef={cropperRef}
          actionCallback={handleCropImage}
          closeCallback={handleCloseCropModal}
        />
      )}
    </>
  );
};

export default AdCallToAction;
