import { createSelector } from 'reselect';

const analyticsStateSelector = (state) => state.basicAdAnalytics;

export const analyticsFetchingSelector = createSelector(analyticsStateSelector, (state) => {
  const {
    acceptanceOverTimeFetching,
    analyticsCampaignFetching,
    analyticsSponsorshipFetching,
    analyticsSponsorshipSetFetching,
    demographicsFetching,
    responsesFetching,
    shortAnswerResponsesFetching,
    totalsFetching,
  } = state;

  return (
    acceptanceOverTimeFetching ||
    analyticsCampaignFetching ||
    analyticsSponsorshipFetching ||
    analyticsSponsorshipSetFetching ||
    demographicsFetching ||
    responsesFetching ||
    shortAnswerResponsesFetching ||
    totalsFetching
  );
});

export const analyticsFilteredFetching = createSelector(analyticsStateSelector, (state) => {
  const {
    acceptanceOverTimeFilteredFetching,
    demographicsFilteredFetching,
    responsesFilteredFetching,
    shortAnswerFilteredResponsesFetching,
    totalsFilteredFetching,
  } = state;

  return (
    acceptanceOverTimeFilteredFetching ||
    demographicsFilteredFetching ||
    responsesFilteredFetching ||
    shortAnswerFilteredResponsesFetching ||
    totalsFilteredFetching
  );
});

export const initialFetchedDataSelector = createSelector(
  analyticsStateSelector,
  (state) => state.initialFetchedData
);

export const acceptanceOptionsSelector = createSelector(
  initialFetchedDataSelector,
  (state) => state.acceptanceOptions
);

export const filterOptionsSelector = createSelector(analyticsStateSelector, (state) => {
  return state.filterOptions;
});

export const acceptanceOverTimeSelector = createSelector(
  analyticsStateSelector,
  (state) => state.acceptanceOverTime
);

export const analyticsSponsorshipSelector = createSelector(
  analyticsStateSelector,
  (state) => state.analyticsSponsorship
);

export const analyticsCampaignSelector = createSelector(
  analyticsStateSelector,
  (state) => state.analyticsCampaign
);

export const analyticsSponsorshipSetSelector = createSelector(
  analyticsStateSelector,
  (state) => state.analyticsSponsorshipSet
);

export const demographicsSelector = createSelector(
  analyticsStateSelector,
  (state) => state.demographics
);

export const influencersByGenderSelector = createSelector(
  demographicsSelector,
  (state) => state?.influencersByGender
);

export const heatMapSelector = createSelector(analyticsStateSelector, (state) => state.heatMap);

export const totalsSelector = createSelector(analyticsStateSelector, (state) => state.totals);

export const isLoadingCSVDataSelector = createSelector(
  analyticsStateSelector,
  (state) => state.isLoadingCSVData
);

export const downloadProgressSelector = createSelector(
  analyticsStateSelector,
  (state) => state.progress
);

export const csvDataForDownloadSelector = createSelector(
  analyticsStateSelector,
  (state) => state.csvDataForDownload
);

export const csvDataNameSelector = createSelector(
  analyticsStateSelector,
  (state) => state.csvDataName
);

export const csvRequestIdSelector = createSelector(
  analyticsStateSelector,
  (state) => state.csvRequestId
);

export const csvStatusSelector = createSelector(analyticsStateSelector, (state) => state.csvStatus);

export const csvFileURLSelector = createSelector(
  analyticsStateSelector,
  (state) => state.csvFileURL
);
