import config from '../../../config';
import { sponsorshipSetConstants } from '../../../constants';
import {
  transformAdResponse,
  transformAdSetResponse,
  transformCampaignResponse,
} from '../../../utils/basicAds';
import {
  transformAcceptanceOverTime,
  transformDemographics,
  transformHeatMap,
  transformTotals,
} from '../../../utils/basicAds/transformResponse';
import { formatToBrandsTimezone } from '../../../utils/date/dateHelpers';
import { removeEmptyQueryStrings } from '../../../utils/formatting';

import { processCharity, processReportingGenders } from '../../../utils/receivedData';

import {
  getUrlFragmentInfluencers,
  processReportingLocations,
  processReportingOs,
} from '../../../utils/reporting';

export class BasicAdAnalyticsService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  formatUrlBasedOnType = (type, baseUrl, campaignId, adSetId, adId) => {
    const cid = campaignId;
    const ssid = adSetId;
    let url = '';
    switch (type) {
      case 'campaign':
        url = `${baseUrl}/campaigns/${cid}`;
        break;
      case 'ad-set':
        url = `${baseUrl}/campaign/${cid}/ad-sets/${ssid}`;
        break;
      case 'ad':
        url = `${baseUrl}/campaign/${cid}/ad-sets/${ssid}/ad/${adId}`;
        break;
      default:
        break;
    }
    return url;
  };

  fetchAcceptanceOverTime = async ({
    campaignId,
    adSetId,
    adId,
    type,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    brandId,
  }) => {
    const baseUrl = `${config.campaignsAPI}/v1/basic-ads`;
    const queryParams = new URLSearchParams(
      removeEmptyQueryStrings({
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      })
    );

    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url: `${url}/analytics/acceptance-over-time?${queryParams.toString()}`,
      method: 'GET',
      useCustomToken: true,
    });

    return transformAcceptanceOverTime(response.data);
  };

  fetchAnalyticsCampaign = async (campaignId) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v2/basic-ads/campaigns/${campaignId}`,
      useCustomToken: true,
    });

    const { id, createdDate, name, brandLogoKey, charityName, sponsorshipsSets } =
      transformCampaignResponse(response.data);

    return {
      id,
      createdDate,
      name,
      brandLogoKey,
      charityName,
      sponsorshipsSets,
    };
  };

  fetchAnalyticsSponsorship = async ({ campaignId, adSetId, adId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-sets/${adSetId}/ad/${adId}`,
      method: 'GET',
      useCustomToken: true,
    });

    const {
      id,
      name,
      adVideo,
      coverTileUri,
      detail,
      cta,
      smsMessage,
      playImpressionUrl,
      videoCompletionUrl,
      videoKey,
      videoWidth,
      videoHeight,
      mediaType,
      ctaCaption,
      ctaLink,
      ctaButtonText,
    } = transformAdResponse(response.data);

    return {
      id,
      name,
      adCover: coverTileUri || '',
      adVideo: adVideo || '',
      smsMessage,
      impressionsUrl: playImpressionUrl || '',
      completionUrl: videoCompletionUrl || '',
      ctaCover: cta?.imageUrl || '',
      ctaLink: ctaLink || '',
      ctaButtonText,
      ctaTrackingScript: cta?.adScript || '',
      ctaDiscount: cta?.discountCode || '',
      trackingUrl: cta?.clickTrackingUrl || '',
      videoKey: videoKey || '',
      detail: detail || null,
      videoWidth,
      videoHeight,
      ctaCaption,
      mediaType,
    };
  };

  fetchAnalyticsSponsorshipSet = async ({ campaignId, adSetId, utcString }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/basic-ads/campaign/${campaignId}/ad-sets/${adSetId}`,
      method: 'GET',
      useCustomToken: true,
    });
    const {
      id,
      name,
      isUniteIncentive,
      includeAnyTags,
      includeLocations,
      deviceType,
      dailyBudget,
      totalBudget,
      ageRanges,
      genders,
      cashReward,
      donationReward,
      weAre8Fee,
      pricePerMessage,
      scheduleFrom,
      scheduleTo,
      timezoneInfo,
      charityId,
      charityName,
      charityLogoUri,
      isEightPixelEnabled,
      pixelConversionEvent,
    } = transformAdSetResponse(response.data);

    return {
      id,
      name,
      isEightPixelEnabled,
      pixelConversionEvent,
      isUnite: isUniteIncentive ? isUniteIncentive : false,
      ageRanges: ageRanges.length > 0 ? ageRanges : [],
      gender: genders
        ? processReportingGenders(genders, sponsorshipSetConstants.genderFilteredOptions)
        : [],
      deviceType: deviceType ? processReportingOs(deviceType) : [],
      totalBudget,
      dailyBudget,
      startDate: scheduleFrom ? formatToBrandsTimezone(scheduleFrom, utcString) : '',
      endDate: scheduleTo ? formatToBrandsTimezone(scheduleTo, utcString) : '',
      locations: includeLocations ? processReportingLocations(includeLocations) : [],
      includeTags: includeAnyTags ? includeAnyTags : [],
      selectedCharity: charityId ? processCharity(charityId, charityName, charityLogoUri) : {},
      cashReward: cashReward ? cashReward : '',
      donationReward: donationReward ? donationReward : '',
      weAre8Fee: weAre8Fee ? weAre8Fee : '',
      pricePerMessage: pricePerMessage ? pricePerMessage : '',
      timezoneInfo,
    };
  };

  fetchDemographics = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    activity,
    brandId,
  }) => {
    const baseUrl = `${config.campaignsAPI}/v1/basic-ads`;

    const queryParams = new URLSearchParams(
      removeEmptyQueryStrings({
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        activity,
        brandId,
      })
    );
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url: `${url}/analytics/demographics?${queryParams.toString()}`,
      method: 'GET',
      useCustomToken: true,
    });

    return transformDemographics(response.data);
  };

  fetchHeatMap = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    activity,
    brandId,
  }) => {
    const baseUrl = `${config.campaignsAPI}/v1/basic-ads`;
    const queryParams = new URLSearchParams(
      removeEmptyQueryStrings({
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        activity,
        brandId,
      })
    );
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url: `${url}/analytics/heatmap?${queryParams.toString()}`,
      method: 'GET',
      useCustomToken: true,
    });

    return transformHeatMap(response.data);
  };

  fetchDataForCSV = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    paginationId,
    csvDataType,
    brandId,
  }) => {
    const urlFragmentInfluencers = getUrlFragmentInfluencers(csvDataType);
    const query =
      csvDataType === 'fullReport'
        ? `?includeAnswersEvents=true&paginationId=${paginationId}`
        : `?paginationId=${paginationId}`;
    let unitName, unitId;
    switch (type) {
      case 'campaign':
        unitName = 'campaign';
        unitId = campaignId;
        break;
      case 'ad-set':
        unitName = 'sponsorshipset';
        unitId = adSetId;
        break;
      case 'ad':
        unitName = 'sponsorship';
        unitId = adId;
        break;
      default:
        break;
    }

    const url = `${config.analyticsAPI}/watchandrespond/reporting/${urlFragmentInfluencers}/${unitName}/${unitId}${query}`;

    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        url,
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      newVersionApi: '2.0',
      useCustomToken: true,
    });

    return response.data;
  };

  fetchTotals = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    brandId,
  }) => {
    const baseUrl = `${config.campaignsAPI}/v1/basic-ads`;
    const queryParams = new URLSearchParams(
      removeEmptyQueryStrings({
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      })
    );

    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url: `${url}/analytics/totals?${queryParams.toString()}`,
      method: 'GET',
      body: {
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      useCustomToken: true,
    });

    return transformTotals(response.data);
  };

  fetchSponsorshipSetBySponsorshipId = async ({ sponsorshipId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/sponsorshipsets/sponsorship/${sponsorshipId}`,
      method: 'GET',
      useCustomToken: true,
    });

    return response.data;
  };

  fetchCSVCompletionStatus = async (requestId) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/performance-report/${requestId}`,
      method: 'GET',
    });

    return response.data;
  };

  fetchPerformanceReportingCSV = async ({ body, csvDataType, type }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/performance-report/${type}`,
      body,
      method: 'POST',
    });

    return { requestId: response.data };
  };
}
