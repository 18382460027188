import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { Checkbox } from '../../../../FormElements/Checkbox';
import { ReportingSelect } from '../../../../FormElements/Selects/ReportingSelect';

import { Button } from '../../../../common/Button';
import { SvgIcon } from '../../../../common/SvgIcon';

import { setInputDateFormat } from '../../../../../utils/receivedData';
import { demographicsFiltersValidationSchema } from './demographicsFiltersValidationSchema';

import { useT } from '@transifex/react';
import { useRouter } from '../../../../../hooks/useRouter';
import s from '../ReportingFilters.module.scss';
import DownloadCSVButton from '../components/DownloadCSVButton/DownloadCSVButton';

const DemographicFilters = ({
  demographicsFilterRequest,
  filterOptions,
  initialFetchedData,
  handleDownloadCSV,
  countryCode,
  type,
  brandIdFromAdmin,
}) => {
  const { query } = useRouter();

  const campaignId = query.cid;
  const adSetId = query.ssid;
  const adId = query.sid;
  const t = useT();
  const { handleSubmit, control, reset, getValues, setValue } = useForm({
    defaultValues: {
      city: filterOptions?.demographics?.demographicsCity,
      gender: filterOptions?.demographics?.demographicsGender,
      platform: filterOptions?.demographics?.demographicsDevice,
      fromAge: filterOptions?.demographics?.demographicsAgeFrom,
      toAge: filterOptions?.demographics?.demographicsAgeTo,
      allAges: false,
      activity: filterOptions?.demographics?.demographicsActivity,
      question: filterOptions?.demographics?.demographicsQuestion,
      responseFilter: filterOptions?.demographics?.demographicsResponse,
      initialDemographicsDateFrom: filterOptions?.demographics?.initialDemographicsDateFrom,
      initialDemographicsDateTo: filterOptions?.demographics?.initialDemographicsDateTo,
      fromDate: filterOptions?.demographics?.demographicsDateFrom,
      toDate: filterOptions?.demographics?.demographicsDateTo,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(demographicsFiltersValidationSchema),
  });
  const watchFromDate = useWatch({ control, name: 'fromDate' });
  const watchToDate = useWatch({ control, name: 'toDate' });
  const watchAllAges = useWatch({ control, name: 'allAges' });

  useEffect(() => {
    if (!watchFromDate && !watchToDate)
      reset({
        fromDate: filterOptions?.demographics?.demographicsDateFrom,
        toDate: filterOptions?.demographics?.demographicsDateTo,
      });
  }, [
    reset,
    watchFromDate,
    watchToDate,
    filterOptions?.demographics?.demographicsDateFrom,
    filterOptions?.demographics?.demographicsDateTo,
  ]);

  const ageRanges = useMemo(() => {
    const lastItemIndex = initialFetchedData?.agesOptions.length - 1;
    return {
      minAge: initialFetchedData?.agesOptions[0],
      maxAge: initialFetchedData?.agesOptions[lastItemIndex],
    };
  }, [initialFetchedData]);

  const hanldeAllAgesChange = useCallback(
    (e, onChange) => {
      if (e?.target?.checked) {
        setValue('fromAge', ageRanges?.minAge);
        setValue('toAge', ageRanges?.maxAge);
      }
      onChange(e.target.checked);
    },
    [ageRanges, setValue]
  );

  const resetForm = useCallback(() => {
    reset();
    demographicsFilterRequest({ query: getValues(), campaignId, adSetId, adId, type });
  }, [reset, demographicsFilterRequest, getValues, campaignId, adSetId, adId, type]);

  const handleSubmitDemographicsFilter = useCallback(() => {
    demographicsFilterRequest({ query: getValues(), campaignId, adSetId, adId, type });
  }, [demographicsFilterRequest, getValues, campaignId, adSetId, adId, type]);

  return (
    <div className={s['filters']}>
      <form onSubmit={handleSubmit(handleSubmitDemographicsFilter)}>
        <div className={s['filters-row']}>
          <div className={s['filters-row__title']}>{t('Filter by:')}</div>
          <div className={s['filters-row__battery']}>
            <div className={s['item']}>
              <ReportingSelect
                name="city"
                options={initialFetchedData?.citiesOptions}
                // handleSelect={handleDemographicOptionSelect('demographicsCity')}
                placeholder={t('City')}
                control={control}
                disabled={initialFetchedData?.citiesOptions?.length === 0}
                defaultValue={filterOptions?.demographics?.demographicsCity}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="gender"
                options={initialFetchedData?.gendersOptions}
                // handleSelect={handleDemographicOptionSelect('demographicsGender')}
                placeholder={t('Gender')}
                control={control}
                disabled={initialFetchedData?.gendersOptions?.length === 0}
                defaultValue={filterOptions?.demographics?.demographicsGender}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="platform"
                options={initialFetchedData?.osOptions}
                // handleSelect={handleDemographicOptionSelect('demographicsDevice')}
                placeholder={t('Device')}
                control={control}
                disabled={initialFetchedData?.osOptions?.length === 0}
                defaultValue={filterOptions?.demographics?.demographicsDevice}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="fromAge"
                options={initialFetchedData?.agesOptions}
                // handleSelect={handleDemographicOptionSelect('demographicsAgeFrom')}
                placeholder={t('Age From')}
                control={control}
                disabled={initialFetchedData?.agesOptions?.length === 0 || watchAllAges}
                defaultValue={filterOptions?.demographics?.demographicsAgeFrom}
              />
            </div>
            <div className={s['item']}>
              <ReportingSelect
                name="toAge"
                options={initialFetchedData?.agesOptions}
                // handleSelect={handleDemographicOptionSelect('demographicsAgeTo')}
                placeholder={t('Age To')}
                control={control}
                disabled={initialFetchedData?.agesOptions?.length === 0 || watchAllAges}
                defaultValue={filterOptions?.demographics?.demographicsAgeTo}
              />
            </div>
            <div className={s['item-age__all']}>
              <Controller
                control={control}
                name="allAges"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      label={t('All ages')}
                      name="allAges"
                      value="allAges"
                      checked={value}
                      onChange={(e) => hanldeAllAgesChange(e, onChange)}
                      disabled={initialFetchedData?.agesOptions?.length === 0}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className={s['filters-row']}>
          <div className={s['filters-row__battery']}>
            <div className={s['item-date__section']}>
              <Controller
                name="fromDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className={s['filters__date-from-wrap']}>
                      <DatePicker
                        className="react-datepicker__custom-input-gray"
                        closeOnScroll={true}
                        dateFormat={setInputDateFormat(countryCode, true)}
                        selected={value}
                        selectsStart
                        startDate={value}
                        endDate={watchToDate}
                        onChange={(date) => onChange(new Date(date))}
                        placeholderText={t('Date From')}
                      />
                      <SvgIcon name="date-picker" />
                    </div>
                  );
                }}
              />
              <div className={s['item-to']}>{t('to')}</div>
              <Controller
                name="toDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className={s['filters__date-to-wrap']}>
                      <DatePicker
                        className="react-datepicker__custom-input-gray"
                        closeOnScroll={true}
                        dateFormat={setInputDateFormat(countryCode, true)}
                        selected={value}
                        onChange={(date) => onChange(new Date(date))}
                        selectsEnd
                        startDate={watchFromDate}
                        minDate={watchFromDate}
                        endDate={value}
                        placeholderText={t('Date To')}
                      />
                      <SvgIcon name="date-picker" />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className={s['filters-row']}>
          <div className={s['buttons']}>
            <div className={s['buttons-filter']}>
              <div className={s['buttons-filter__submit']}>
                <Button
                  type="submit"
                  styling="filter"
                  customText={t('Apply Filters')}
                  disabled={false}
                  fullwidth
                />
              </div>
              <div className={s['link-text']} onClick={resetForm}>
                {t('clear all filters')}
              </div>
            </div>
            {!brandIdFromAdmin && (
              <DownloadCSVButton handleDownloadCSV={handleDownloadCSV} getValues={getValues} />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DemographicFilters;
