import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';

import { DisabledNavLink } from '../../common/DisabledNavLink';

import { useSelector } from 'react-redux';
import { MenuOptions } from '../../common/MenuOptions';
import { SvgIcon } from '../../common/SvgIcon';
import s from './BasicAdSetMenuSection.module.scss';

import classNames from 'classnames';
import { routes } from '../../../constants';
import basicCampaignMenu from '../../../constants/basic-campaign-menu';
import { useBasicActiveMenuItem } from '../../../hooks/useBasicActiveMenuItem';
import {
  basicAdAdSetsSelector,
  basicAdCampaignSelector,
  createNewBasicAdAd,
  deleteBasicAdAdSet,
  duplicateBasicAdAdSet,
} from '../../../store/basicAdData';
import { AnimatedMenu } from '../AnimatedMenu';
import BasicAdMenuSection from '../BasicAdMenuSection/BasicAdMenuSection';

const BasicAdSetMenuSection = ({
  handleSponsorshipSetActivation,
  sponsorshipSet,
  sponsorshipSetId,
  campaignId,
  sponsorshipSetMode,
  sponsorshipEditId,
  sponsorshipMode,
  stickyMenuItem,
  adSet,
  activeSponsorshipSet,
  isArchived,
  unitIsApproved,
  index,
}) => {
  const sponsorshipSetNav = basicCampaignMenu.menuItems.find(
    (menuItem) => menuItem.name === 'Ad Set'
  );
  const sponsorshipSets = useSelector(basicAdAdSetsSelector);
  const campaign = useSelector(basicAdCampaignSelector);

  const isDisabled = useMemo(
    () => !campaign.isCreated || isArchived,
    [campaign.isCreated, isArchived]
  );

  const { activeMenuItem } = useBasicActiveMenuItem({
    type: 'adSet',
    adSetId: adSet.id,
    newItemIndex: adSet.id === 'new' ? index + 1 : undefined,
  });
  const [showSubMenu, setShowSubmenu] = useState(true);

  useEffect(() => {
    setShowSubmenu(activeMenuItem);
  }, [activeMenuItem]);

  const adSetName = useMemo(() => {
    if (adSet.id === 'new') {
      return activeSponsorshipSet.id === 'new' &&
        typeof activeSponsorshipSet.index !== 'undefined' &&
        typeof adSet.index !== 'undefined' &&
        activeSponsorshipSet.index === adSet.index
        ? activeSponsorshipSet.name
        : adSet.name;
    } else return adSet.id === activeSponsorshipSet.id ? activeSponsorshipSet.name : adSet.name;
  }, [activeSponsorshipSet, adSet.id, adSet.index, adSet.name]);

  // // The 'timing' and 'budget' menu options depend on sponsorshipSet id creation.
  // const isMenuItemDisabled = (item) =>
  //   item === 'timing' || item === 'budget' || item === 'charity' ? !sponsorshipSet?.id : isDisabled;
  return (
    <div className={s['sponsorshipset-menu']}>
      <div className={s['sponsorshipset-menu__item']}>
        <button
          className={
            activeMenuItem && !isDisabled
              ? classNames(s['sponsorshipset-menu__item-title'], s['active'])
              : isDisabled
              ? classNames(s['sponsorshipset-menu__item-title'], s['disabled'])
              : s['sponsorshipset-menu__item-title']
          }
        >
          <DisabledNavLink
            to={
              adSet.id === 'new'
                ? generatePath(routes.BASIC_AD.SPONSORSHIP_SET_CREATE_MULTI, {
                    cid: campaignId,
                    newIndex: index + 1,
                  })
                : generatePath(sponsorshipSetNav.editPath, {
                    cid: campaignId,
                    ssid: adSet.id,
                  })
            }
            isDisabled={isDisabled}
            isOpen={activeMenuItem}
            fragment="adset-name"
          >
            <div className={s['expand-icon']}>
              <div
                className={showSubMenu ? s['open'] : s['closed']}
                onClick={() => setShowSubmenu((prevState) => !prevState)}
              >
                <SvgIcon name="expand" />
              </div>
            </div>
            {adSet.isDirty && <SvgIcon name="warning" />}
            <div className={s['adset-icon']}>
              <SvgIcon name="adset" />
            </div>
            <span>{adSetName || 'New Ad Set'}</span>
          </DisabledNavLink>
        </button>
        <MenuOptions
          name="Ad Set"
          adSetId={adSet.id}
          items={sponsorshipSets}
          createFunction={createNewBasicAdAd}
          deleteFunction={deleteBasicAdAdSet}
          duplicateFunction={duplicateBasicAdAdSet}
          type="adSet"
          campaignId={campaignId}
          isDisabled={isDisabled}
          isOpen={activeMenuItem && !isDisabled}
          unitIsApproved={unitIsApproved}
          campaign={campaign}
          adSetIndex={index}
        />
      </div>

      <AnimatedMenu
        show={showSubMenu}
        menuItems={sponsorshipSetNav.submenuItems}
        campaignId={campaignId}
        adSetId={adSet.id}
        isDisabled={isDisabled}
        stickyMenuItem={stickyMenuItem}
      >
        {adSet.ads.map((ad, idx) => (
          <BasicAdMenuSection
            ad={ad}
            key={ad.id}
            adSet={adSet}
            sponsorshipMode={sponsorshipMode}
            campaignId={campaignId}
            sponsorshipSetId={sponsorshipSetId}
            sponsorshipEditId={sponsorshipEditId}
            isArchived={isArchived}
            stickyMenuItem={stickyMenuItem}
            index={idx}
            unitIsApproved={ad.deliveryStatus.toLowerCase() === 'approved' || !ad.isDraft}
          />
        ))}
      </AnimatedMenu>
    </div>
  );
};

export default BasicAdSetMenuSection;
