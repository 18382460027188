import React, { useCallback } from 'react';

import ReactPlayer from 'react-player';
import { useT } from '@transifex/react';

import { DeliveryStatus } from '../DeliveryStatus';
import { SvgIcon } from '../common/SvgIcon';

import { Switch } from '../FormElements/Switch';

import { numberToCurrency } from '../../utils/budget';

import { routes } from '../../constants';
import s from './BasicAdsTable.module.scss';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import classNames from 'classnames';
import Tooltip from '../common/Tooltip/Tooltip';
import {
  formatFrequency,
  formatRate,
  processReceivedText,
  setBrandDateFormat,
} from '../../utils/receivedData';
import { FeatureFlag } from '../common/FeatureFlag';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import {
  fetchBasicCampaignNamesAsync,
  fetchBasicSponsorshipSetNamesAsync,
} from '../../store/brand';

const BasicAdsTable = ({
  brandInfo,
  tableHeader,
  campaignData,
  adSetsData,
  adsData,
  isArchive,
  handleArchive,
  handleRejectionReasonClick,
  handleCampaignActivation,
  handleSponsorshipSetActivation,
  handleSponsorshipActivation,
  handleQuestionsClick,
  duplicateCampaign,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  const getNumberToCurrency = (value, noDecimals) =>
    value ? numberToCurrency(value, noDecimals) : '-';

  const tableContainerRef = useCallback((node) => {
    function DoubleScroll(element) {
      var scrollbar = document.createElement('div');
      scrollbar.appendChild(document.createElement('div'));
      scrollbar.style.overflow = 'auto';
      scrollbar.style.overflowY = 'hidden';
      scrollbar.firstChild.style.width = element.scrollWidth + 'px';
      scrollbar.firstChild.style.paddingTop = '1px';
      scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
      scrollbar.onscroll = function () {
        element.scrollLeft = scrollbar.scrollLeft;
      };
      element.onscroll = function () {
        scrollbar.scrollLeft = element.scrollLeft;
      };
      element.parentNode.insertBefore(scrollbar, element);
    }

    if (node) DoubleScroll(node);
  }, []);

  useUnmount(() => {
    dispatch(fetchBasicCampaignNamesAsync.success({ clear: true, response: { data: [] } }));
    dispatch(fetchBasicSponsorshipSetNamesAsync.success({ clear: true, response: { data: [] } }));
  });

  return (
    <>
      <div className={s['table-wrap']} ref={tableContainerRef}>
        <div className={s['table']}>
          <div className={s['table-head']}>
            {tableHeader.map((cell, index) => (
              <div key={index} className={s['table-head__cell']}>
                {t(cell?.title)}
              </div>
            ))}
          </div>
          <div className={s['table-body']}>
            {campaignData &&
              campaignData?.data.map((campaign, index) => {
                // const { completed, accepted, clickThrough } =
                //   campaigns.data.find((camp) => camp.id === campaign.id) || {};
                return (
                  <div key={index} className={s['table-row']}>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__switch']}>
                        <Switch
                          id={campaign?.id}
                          value={campaign?.id}
                          checked={campaign?.isActive}
                          name={campaign?.name}
                          onChange={handleCampaignActivation}
                          disabled={isArchive}
                        />
                      </div>
                    </div>
                    <div className={s['table-cell__link']}>
                      <Link
                        className={s['name']}
                        to={generatePath(routes.BASIC_AD.EDIT_CAMPAIGN, {
                          cid: campaign?.id,
                        })}
                      >
                        {campaign?.name}
                      </Link>
                      <div className={s['reporting']}>
                        <Link
                          to={
                            generatePath(`${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}`, {
                              cid: campaign?.id,
                            }) + '?type=overview'
                          }
                          className={classNames(s['reporting-performance'])}
                        >
                          <SvgIcon name="performance" />
                          <span className={s['link']} id={campaign?.id}>
                            {t('Performance')}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className={s['table-cell__status']}>
                      <div className={s['table-cell__status-inner']}>
                        <div className={s['status-info']}>
                          <DeliveryStatus status={campaign.deliveryStatus} />
                          <div className={s['status-text']}>
                            {processReceivedText(campaign.deliveryStatus)}
                          </div>
                        </div>
                        {campaign?.deliveryStatus === 'rejected' ? (
                          <div
                            id={campaign?.id}
                            className={s['reason-link']}
                            onClick={handleRejectionReasonClick('campaign')}
                          >
                            {t('Details')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(campaign?.earliestStartDate, brandInfo.countryCode)}
                    </div>
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(campaign?.latestEndDate, brandInfo.countryCode)}
                    </div>
                    <div className={s['table-cell']}>
                      {numberToCurrency(parseFloat(campaign?.totalBudget), false, false, 6)}
                    </div>
                    <div className={s['table-cell']}>
                      {campaign?.amountSpent
                        ? numberToCurrency(parseFloat(campaign?.amountSpent), false, false, 6)
                        : '-'}
                    </div>
                    {/* <div className={s['table-cell']}>{campaign?.reach}</div> */}
                    <div className={s['table-cell']}>{campaign?.impressions || '-'}</div>
                    <div className={s['table-cell']}>{campaign?.adCompletions || '-'}</div>
                    <div className={s['table-cell']}>{campaign?.clickThrough || '-'}</div>
                    <div className={s['table-cell']}>{formatRate(campaign?.clickThroughRate)}</div>
                    {/* <div className={s['table-cell']}>{campaign?.conversions}</div> */}
                    <div className={s['table-cell']}>{formatRate(campaign?.conversionRate)}</div>
                    <FeatureFlag name="WA8-2359">
                      <div className={s['table-cell']}>
                        {formatFrequency(campaign?.adFrequency)}
                      </div>
                    </FeatureFlag>

                    <div className={s['table-cell__actions']}>
                      {!campaign?.isArchived ? (
                        <div className={s['archive-section']}>
                          <button
                            className={s['archive']}
                            type="button"
                            onClick={() => handleArchive(campaign?.id)}
                          >
                            <Tooltip
                              content={t('Archive')}
                              customIcon={<SvgIcon name="archive" />}
                              customClass="action"
                            />
                          </button>
                        </div>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                );
              })}
            {adSetsData &&
              adSetsData?.data.map((adSet, index) => {
                const { completed, accepted, clickThrough } = adSet;

                return (
                  <div key={index} className={s['table-row']}>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__switch']}>
                        <Switch
                          id={adSet?.id}
                          value={adSet?.id}
                          checked={adSet?.isActive}
                          name={adSet?.name}
                          onChange={handleSponsorshipSetActivation}
                          disabled={isArchive}
                        />
                      </div>
                    </div>
                    <div className={s['table-cell__link']}>
                      <Link
                        className={s['name']}
                        to={
                          isArchive
                            ? generatePath(routes.BASIC_AD.CAMPAIGN_SUMMARY, {
                                cid: adSet?.campaignId,
                              })
                            : generatePath(routes.BASIC_AD.SPONSORSHIP_SET_EDIT, {
                                cid: adSet?.campaignId,
                                ssid: adSet?.id,
                              })
                        }
                      >
                        {adSet?.name}
                      </Link>
                      <div className={s['reporting']}>
                        <Link
                          to={
                            generatePath(
                              `${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}${routes.BASIC_AD.SPONSORSHIP_SET_ROUTE}`,
                              {
                                cid: adSet?.campaignId,
                                ssid: adSet?.id,
                              }
                            ) + '?type=overview'
                          }
                          className={classNames(s['reporting-performance'])}
                        >
                          <SvgIcon name="performance" />
                          <span className={s['link']} id={adSet?.campaignId}>
                            {t('Performance')}
                          </span>
                        </Link>
                        <Link
                          to={
                            generatePath(
                              `${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}`,
                              {
                                cid: adSet?.campaignId,
                                ssid: adSet?.id,
                              }
                            ) + '?type=demographics'
                          }
                          className={classNames(s['reporting-demographics'])}
                        >
                          <SvgIcon name="demographics" />
                          <span className={s['link']} id={adSet?.campaignId}>
                            {t('Demographics')}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className={s['table-cell__status']}>
                      <div className={s['table-cell__status-inner']}>
                        <div className={s['status-info']}>
                          <DeliveryStatus status={adSet.deliveryStatus} />
                          <div className={s['status-text']}>
                            {processReceivedText(adSet.deliveryStatus)}
                          </div>
                        </div>
                        {adSet?.deliveryStatus === 'rejected' ? (
                          <div
                            id={adSet?.id}
                            className={s['reason-link']}
                            onClick={handleRejectionReasonClick('adset')}
                          >
                            {t('Details')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    {/* <div className={s['table-cell__link']} id={adSet?.campaignId}>
                      <div className={s['table-cell__line-clamp']}>{adSet?.campaignName}</div>
                    </div> */}
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(adSet?.startDate, brandInfo.countryCode, true)}
                    </div>
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(adSet?.endDate, brandInfo.countryCode, true)}
                    </div>
                    <div className={s['table-cell']}>
                      {getNumberToCurrency(adSet?.totalBudget, true)}
                    </div>
                    <div className={s['table-cell']}>
                      {adSet?.amountSpent
                        ? numberToCurrency(parseFloat(adSet?.amountSpent), false, false, 6)
                        : '-'}
                    </div>
                    <div className={s['table-cell']}>{getNumberToCurrency(adSet?.dailyBudget)}</div>
                    <div className={s['table-cell']}>{adSet?.impressions || '-'}</div>
                    <div className={s['table-cell']}>{adSet?.adCompletions || '-'}</div>
                    <div className={s['table-cell']}>{adSet?.clickThrough || '-'}</div>
                    <div className={s['table-cell']}>{formatRate(adSet?.clickThroughRate)}</div>
                    {/* <div className={s['table-cell']}>{adSet?.conversions}</div> */}
                    <div className={s['table-cell']}>{formatRate(adSet?.conversionRate)}</div>
                    <FeatureFlag name="WA8-2359">
                      <div className={s['table-cell']}>{formatFrequency(adSet?.adFrequency)}</div>
                    </FeatureFlag>
                  </div>
                );
              })}
            {adsData &&
              adsData?.data.map((ad, index) => {
                const { completed, accepted, clickThrough } = ad;
                return (
                  <div key={index} className={s['table-row']}>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__switch']}>
                        <Switch
                          id={ad?.id}
                          value={ad?.id}
                          checked={ad?.isActive}
                          name={ad?.name}
                          onChange={handleSponsorshipActivation}
                          disabled={isArchive}
                        />
                      </div>
                    </div>
                    <div className={s['table-cell__link']}>
                      <Link
                        className={s['name']}
                        to={
                          isArchive
                            ? generatePath(routes.BASIC_AD.CAMPAIGN_SUMMARY, {
                                cid: ad?.campaignId,
                              })
                            : generatePath(routes.BASIC_AD.SPONSORSHIP_EDIT, {
                                cid: ad?.campaignId,
                                ssid: ad?.adSetId,
                                sid: ad?.id,
                              })
                        }
                      >
                        {ad?.name}
                      </Link>
                      <div className={s['reporting']}>
                        <Link
                          to={
                            generatePath(
                              `${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}${routes.BASIC_AD.SPONSORSHIP_SET_ROUTE}${routes.BASIC_AD.SPONSORSHIP_ROUTE}`,
                              {
                                cid: ad?.campaignId,
                                ssid: ad?.adSetId,
                                sid: ad?.id,
                              }
                            ) + '?type=overview'
                          }
                          className={classNames(s['reporting-performance'])}
                        >
                          <SvgIcon name="performance" />
                          <span className={s['link']} id={ad?.campaignId}>
                            {t('Performance')}
                          </span>
                        </Link>
                        <Link
                          to={
                            generatePath(
                              `${routes.REPORTING}${routes.BASIC_AD.CAMPAIGN_ROUTE}${routes.BASIC_AD.SPONSORSHIP_SET_ROUTE}${routes.BASIC_AD.SPONSORSHIP_ROUTE}`,
                              {
                                cid: ad?.campaignId,
                                ssid: ad?.adSetId,
                                sid: ad?.id,
                              }
                            ) + '?type=demographics'
                          }
                          className={classNames(s['reporting-demographics'])}
                        >
                          <SvgIcon name="demographics" />
                          <span className={s['link']} id={ad?.campaignId}>
                            {t('Demographics')}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className={s['table-cell__status']}>
                      <div className={s['table-cell__status-inner']}>
                        <div className={s['status-info']}>
                          <DeliveryStatus status={ad.deliveryStatus} />
                          <div className={s['status-text']}>
                            {processReceivedText(ad.deliveryStatus)}
                          </div>
                        </div>
                        {ad?.deliveryStatus === 'rejected' ? (
                          <div
                            id={ad?.id}
                            className={s['reason-link']}
                            onClick={handleRejectionReasonClick('ad')}
                          >
                            {t('Details')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      <div className={s['cell-media']}>
                        {ad.mediaType === 'video' ? (
                          ad.mediaDetails.uri ? (
                            <ReactPlayer
                              className={s['player']}
                              volume={1}
                              controls={true}
                              height="100%"
                              width="100%"
                              url={ad.mediaDetails.uri || ''}
                            />
                          ) : (
                            <div className={s['no-content']}>{t('No content')}</div>
                          )
                        ) : ad.mediaDetails ? (
                          <img src={ad.mediaDetails.uri} alt="" />
                        ) : (
                          <div className={s['no-content']}>{t('No content')}</div>
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__line-clamp']}>{ad.ctaWebLink}</div>
                    </div>
                    <div className={s['table-cell']}>{ad?.impressions || '-'}</div>
                    <div className={s['table-cell']}>{ad?.adCompletions || '-'}</div>
                    <div className={s['table-cell']}>{ad?.clickThrough || '-'}</div>
                    <div className={s['table-cell']}>{formatRate(ad?.clickThroughRate)}</div>
                    {/* <div className={s['table-cell']}>{ad?.conversions}</div> */}
                    <div className={s['table-cell']}>{formatRate(ad?.conversionRate)}</div>
                    <FeatureFlag name="WA8-2359">
                      <div className={s['table-cell']}>{formatFrequency(ad?.adFrequency)}</div>
                    </FeatureFlag>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicAdsTable;
