import { isListEmpty } from '../../hooks/useLanguage/useLanguage';
export const transformCampaignResponse = (campaign) => {
  console.log('transformCampaignResponse', campaign);

  return {
    id: campaign.id,
    name: campaign.name,
    brandName: campaign.campaignBrand.name,
    brandId: campaign.campaignBrand.id,
    brandLogoUri: campaign.campaignBrand.logoUri,
    brandInfo: 'brand info displayed here',
    brandCurrencyCode: campaign.campaignBrand.currencyCode,
    spendingLimit: '0',
    isActive: campaign.isActive,
    isReadOnly: false,
    category: campaign.objective,
    deliveryStatus: campaign.deliveryStatus,
    moderationStatus: 'approved', // default value
    charityId: campaign.charity.id,
    charityName: campaign.charity.name,
    charityLogoUri: campaign.charity.logoUri,
    isArchive: campaign.isArchived,
    sponsorshipsSets: campaign.adSets.map((adSet) => ({
      id: adSet.id,
      name: adSet.name,
      isUniteIncentive: false,
      totalBudget: adSet.totalBudget,
      dailyBudget: adSet.dailyBudget,
      participantsGoal: 0,
      cashReward: '0.08', //  a dummy value
      donationReward: '0.1', //  a dummy value
      pricePerMessage: '0.28', //  a dummy value
      weAre8Fee: '0.1', //  a dummy value
      scheduleFrom: adSet.startDate,
      scheduleTo: adSet.endDate,
      timezoneInfo: {
        utcString: campaign.campaignBrand.timezoneId,
        value: 'British Summer Time', //  based on UTC
        abbr: 'BST', // dummy
        offset: 1.0, // dummy
        isDst: true, // dummy
        text: '(UTC+01:00) Edinburgh, London', // dummy
      },
      isActive: adSet.isActive,
      isReadOnly: false,
      charityId: campaign.charity.id,
      charityName: campaign.charity.name,
      charityLogoUri: campaign.charity.logoUri,
      ageRanges: adSet.ageRanges,
      genders: adSet.genders,
      deviceType: adSet.deviceType,
      deliveryStatus: adSet.deliveryStatus,
      moderationStatus: 'approved', // dummy
      isEightPixelEnabled: adSet.isEightPixelEnabled,
      pixelConversionEvent: adSet.pixelConversionEvent,
      interests: [],
      sponsorships: adSet.ads.map((ad) => ({
        id: ad.id,
        isActive: ad.isActive,
        isReadOnly: false,
        name: ad.name,
        videoHlsUri: ad.mediaDetails?.uri,
        type: 'watch_and_respond', // dummy
        videoKey: `${campaign.id}/${ad.id}-CampaignVideo.mp4`,
        detail: {
          yesNoQuestions: [
            {
              yesTags: [],
              noTags: [],
              id: campaign.id,
              questionText: 'Is this an automation test from Rest Assured ?', // dummy
              questionOrder: 1,
            },
          ],
        },
        cta: {
          imageUrl: ad.mediaDetails?.thumbnailUri,
          imageWidth: '300', // dummy
          imageHeight: '300', // dummy
          imageAspectRatio: '1:1', // dummy
          webLink: ad.cta.url,
        },
      })),
    })),
  };
};

export const transformAdSetResponse = (adSet) => {
  console.log('transformAdSetResponse', adSet);

  return {
    id: adSet.id,
    name: adSet.name,
    includeAnyTags: adSet.includeAnyTags ?? [],
    includeAndTags: adSet.includeAndTags ?? [],
    excludeTags: adSet.excludeTags ?? [],
    individualIds: adSet.individualIds ?? [],
    includeLocations:
      adSet.includeLocations?.map((loc) => ({
        display: loc.display,
        value: loc.value,
      })) ?? [],
    excludeLocations:
      adSet.excludeLocations?.map((loc) => ({
        display: loc.display,
        value: loc.value,
      })) ?? [],
    isUniteIncentive: adSet.isUniteIncentive,
    totalBudget: adSet.totalBudget,
    dailyBudget: adSet.dailyBudget,
    participantsGoal: adSet.participantsGoal ?? 0,
    cashReward: adSet.cashReward,
    donationReward: adSet.donationReward,
    pricePerMessage: adSet.pricePerMessage,
    weAre8Fee: adSet.weAre8Fee,
    scheduleFrom: adSet.startDate,
    scheduleTo: adSet.endDate,
    timezoneInfo: {
      utcString: adSet.timezoneInfo?.utcString ?? '',
      value: adSet.timezoneInfo?.value ?? '',
      abbr: adSet.timezoneInfo?.abbr ?? '',
      offset: adSet.timezoneInfo?.offset ?? 0,
      isDst: adSet.timezoneInfo?.isDst ?? false,
      text: adSet.timezoneInfo?.text ?? '',
    },
    isActive: adSet.isActive,
    isReadOnly: adSet.isReadOnly,
    charityId: adSet.charityId,
    charityName: adSet.charityName,
    charityLogoUri: adSet.charityLogoUri,
    ageRanges:
      adSet.ageRanges?.map((range) => ({
        ageFrom: range.ageFrom ?? 0,
        ageTo: range.ageTo ?? 0,
      })) ?? [],
    genders: adSet.gender ?? ['any'],
    deviceType: adSet.deviceType ?? 'all',
    deliveryStatus: adSet.deliveryStatus ?? 'unknown',
    moderationStatus: adSet.moderationStatus ?? 'unknown',
    isEightPixelEnabled: adSet.isEightPixelEnabled,
    pixelConversionEvent: adSet.pixelConversionEvent ?? 'unknown',
    interests: adSet.interests ?? ['unknown'],
  };
};

export const transformAdResponse = (ad) => {
  console.log('transformAdResponse', ad);

  return {
    id: ad.id,
    name: ad.name,
    adCover: ad.coverTileUri || '',
    adVideo: ad.mediaDetails.uri || '',
    impressionsUrl: ad.playImpressionUrl || '',
    completionUrl: ad.videoCompletionUrl || '',
    ctaCover: ad.cta?.imageUrl || '',
    ctaLink: ad.cta?.url || '',
    ctaCaption: ad.caption || '',
    mediaType: ad.mediaType || '',
    ctaButtonText: ad.cta?.buttonText || 'Check it out',
    ctaTrackingScript: ad.cta?.adScript || '',
    ctaDiscount: ad.cta?.discountCode || '',
    trackingUrl: ad.cta?.clickTrackingUrl || '',
    videoKey: ad.videoKey || '',
    detail: ad.detail || null,
    videoWidth: ad.videoWidth,
    videoHeight: ad.videoHeight,
  };
};

export const transformUnpublishedDifferences = (res) => {
  console.log('transformUnpublishedDifferences', res);

  const differences = [];

  res.adSets.forEach((adSet) => {
    const adSetDifferences = (adSet.unpublishedChanges || []).map((diff) => ({
      modifiedField: diff.fieldName,
      unpublishedValue: diff.newValue,
      publishedValue: diff.oldValue,
    }));

    if (!isListEmpty(adSetDifferences)) {
      differences.push({
        id: adSet.id,
        createdDate: new Date().toISOString(),
        differences: adSetDifferences,
        campaignLevel: 'sponsorship_set',
        differencesStatus: 'pending',
      });
    }

    adSet.ads.forEach((ad) => {
      const adDifferences = (ad.unpublishedChanges || []).map((diff) => ({
        modifiedField: diff.fieldName,
        unpublishedValue: diff.newValue,
        publishedValue: diff.oldValue,
      }));

      if (!isListEmpty(adDifferences)) {
        differences.push({
          id: ad.id,
          createdDate: new Date().toISOString(),
          differences: adDifferences,
          campaignLevel: 'sponsorship',
          differencesStatus: 'pending',
        });
      }
    });
  });

  // return [transformed];
  return differences;
};

const hasUnpublishedChanges = (entity) => {
  console.log('hasUnpublishedChanges', entity);

  return entity.unpublishedDifferences && entity.unpublishedDifferences.length > 0;
};

export const checkForUnpublishedChanges = (campaign) => {
  console.log('checkForUnpublishedChanges', campaign);
  if (!campaign || !Array.isArray(campaign.adSets)) {
    return false;
  }

  return campaign.adSets.some(
    (adSet) => hasUnpublishedChanges(adSet) || adSet.ads.some(hasUnpublishedChanges)
  );
};

export const transformAcceptanceOverTime = (response) => {
  const categories = Object.keys(response);
  const metricsMap = {
    unknown: 'unknown',
    impressed: 'impressed',
    completed: 'Completed (unique users)',
    viewed_in_feed: 'Viewed in feed (unique users)',
    cta_clicked: 'Click Through (unique users)',
  };
  const series = Object.keys(metricsMap).map((metric) => ({
    name: metricsMap[metric],
    data: categories.map((category) => ({
      name: category,
      x: category,
      y: response[category][metric] || 0,
    })),
  }));

  return {
    xAxis: {
      categories: categories,
    },
    series: series,
  };
};

export const transformHeatMap = (response) => {
  return {
    data: {
      heatMapByInfluencerCountryCode: [],
      heatMapByInfluencerCity: [
        {
          name: 'heatMap',
          data: response.map((entry, index) => ({
            name: entry.city,
            x: index + 1,
            y: entry.city,
            metadata: {
              docs_count: entry.total.toString(),
              city_long: entry.longitude.toString(),
              city_lat: entry.latitude.toString(),
              country_code: entry.countryCode,
              percentage: entry.percentage.toString(),
            },
          })),
        },
      ],
    },
  };
};

export const transformDemographics = (response) => {
  return {
    data: {
      influencersByCountryCode: [
        {
          name: 'countryCode',
          data: [
            {
              name: 'US',
              x: 1,
              y: 'US',
              metadata: {
                countryFlagUrl: 'https://www.countryflags.io/us/flat/64.png',
                countryFullName: 'United States of America',
                percentage: '100',
              },
            },
          ],
        },
      ],
      influencersByRegionCode: [
        {
          name: 'regionCode',
          data: Object.entries(response.Regions || {}).map(([region, data], index) => ({
            name: region,
            x: index + 1,
            y: region,
            metadata: {
              percentage: data.percent.toFixed(1),
            },
          })),
        },
      ],
      influencersByRegionName: [
        {
          name: 'regionName',
          data: Object.entries(response.Regions || {}).map(([region, data], index) => ({
            name: region,
            x: index + 1,
            y: region,
            metadata: {
              percentage: data.percent.toFixed(1),
            },
          })),
        },
      ],
      influencersByCityName: [
        {
          name: 'cityName',
          data: Object.entries(response.Cities || {}).map(([city, data], index) => ({
            name: city,
            x: index + 1,
            y: city,
            metadata: {
              percentage: data.percent.toFixed(1),
            },
          })),
        },
      ],
      influencersByAge: [
        {
          name: 'age',
          data: Object.entries(response.Ages || {}).flatMap(([ageRange, data]) => {
            const ageParts = ageRange.split('-');
            return [
              {
                name: ageParts[0],
                x: ageParts[0],
                y: 1,
                metadata: { percentage: data.percent.toFixed(1) },
              },
              {
                name: ageParts[1],
                x: ageParts[1],
                y: 1,
                metadata: { percentage: data.percent.toFixed(1) },
              },
            ];
          }),
        },
      ],
      influencerByAgeGrouped: [
        {
          name: 'ageGrouped',
          data: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'].map((ageGroup) => ({
            x: response.Ages[ageGroup] ? 1 : 0,
            y: ageGroup,
            metadata: {
              percentage: response.Ages[ageGroup]
                ? response.Ages[ageGroup].percent.toFixed(1)
                : '0',
            },
          })),
        },
      ],
      influencersByGender: [
        {
          name: 'gender',
          data: Object.entries(response.Genders || {}).map(([gender, data]) => ({
            name: gender,
            x: 1,
            y: gender,
            metadata: {
              percentage: data.percent.toFixed(1),
            },
          })),
        },
      ],
      influencersByPlatform: [
        {
          name: 'platform',
          data: Object.entries(response.Devices || {}).map(([platform, data]) => ({
            name: platform.replace(/ /g, '_').toLowerCase(),
            x: 1,
            y: platform.replace(/ /g, '_').toLowerCase(),
            metadata: {
              percentage: data.percent.toFixed(1),
            },
          })),
        },
      ],
    },
  };
};

export const transformTotals = (response) => {
  const { deliveryStatusTotals, lastThirtyDaysCounts } = response;

  return {
    declined: {
      total: deliveryStatusTotals.declined || 0,
      last7Days: '--',
      last30Days: deliveryStatusTotals.declined || 0,
    },
    viewed_in_feed: {
      total: deliveryStatusTotals.impressedCount || 0,
      last7Days: '--',
      last30Days: lastThirtyDaysCounts.impressedCount || 0,
    },
    sponsorship_frequency: {
      total: deliveryStatusTotals.frequency || 0,
      last7Days: '--',
      last30Days: deliveryStatusTotals.frequency || 0,
    },
    completed: {
      total: deliveryStatusTotals.completedCount || 0,
      totalDenominator: 0,
      last7Days: '--',
      last30Days: lastThirtyDaysCounts.completedCount || 0,
    },
    conversions: {
      total: deliveryStatusTotals.conversionCount || 0,
      last7Days: '--',
      last30Days: lastThirtyDaysCounts.conversionCount || 0,
    },
    accepted: {
      total: deliveryStatusTotals.completedCount || 0,
      last7Days: '--',
      last30Days: lastThirtyDaysCounts.completedCount || 0,
    },
    click_through: {
      total: deliveryStatusTotals.clickCount || 0,
      last7Days: '--',
      last30Days: lastThirtyDaysCounts.clickCount || 0,
    },
    video_viewed: {
      total: deliveryStatusTotals.videoViewedCount || 0,
      last7Days: '--',
      last30Days: lastThirtyDaysCounts.videoViewedCount || 0,
    },
    charityDonations: {
      total: deliveryStatusTotals.totalDonated || 0,
    },
    viewed_in_details: {
      total: deliveryStatusTotals.vieviedInDetails || 0,
      last7Days: '--',
      last30Days: lastThirtyDaysCounts.vieviedInDetails || 0,
    },
    costPerEngagement: {
      total: 0.28,
    },
    spent: {
      total: deliveryStatusTotals.totalSpent || 0,
      totalDenominator: 1000,
    },
  };
};
