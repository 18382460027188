export const htmlStringToElement = (html) => {
  var template = document.createElement('template');
  html = html.trim();
  template.innerHTML = html;
  return template.content.firstChild;
};

export const filterDuplicates = (array) => {
  return array.filter((e, i) => array.findIndex((a) => a['name'] === e['name']) === i);
};

export const removeTrailingZeros = (number) => {
  return parseFloat(number);
};

export const countDecimalPlaces = (number) => {
  const stringNumber = String(number);
  return stringNumber.split('.')[1].length;
};

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const removeEmptyQueryStrings = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
};

export const removeIdFromString = (errorMessage) => errorMessage.replace(/[0-9a-fA-F-]{36}/, '');
