import { useT } from '@transifex/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Title } from '../../components/common/Titles/Title';
import { MobileMenu } from '../../components/wrappers/MobileMenu';
import { StickyMenu } from '../../components/wrappers/StickyMenu';

import BasicAdSetMenuSection from '../../components/Menus/BasicAdSetMenuSection/BasicAdSetMenuSection';
import BasicCampaignMenuSection from '../../components/Menus/BasicCampaignMenuSection/BasicCampaignMenuSection';
import { BasicSummaryMenu } from '../../components/Menus/BasicSummaryMenu';
import { MenuPlaceholder } from '../../components/Placeholders/MenuPlaceholder';
import { basicAdCreateSelector, basicAdIdSelector } from '../../store/basicAd/campaign';
import {
  basicAdSponsorshipCreateSelector,
  basicAdSponsorshipEditIdSelector,
  basicAdUpdateSponsorshipSelector,
} from '../../store/basicAd/sponsorship';
import {
  basicAdSponsorshipSetCreateSelector,
  basicAdSponsorshipSetEditIdSelector,
} from '../../store/basicAd/sponsorshipSet';
import {
  basicAdAdSetsSelector,
  basicAdCampaignDataFetchingSelector,
  basicAdCampaignSelector,
  basicAdSummaryAdSetsSelector,
  basicAdUnpublishedChangesSelector,
} from '../../store/basicAdData';
import { stickyMenuItemSelector } from '../../store/ui';

const BasicCampaignMenu = () => {
  const t = useT();

  const campaignEditId = useSelector(basicAdIdSelector);
  const sponsorshipSetId = useSelector(basicAdSponsorshipSetEditIdSelector);
  const sponsorshipEditId = useSelector(basicAdSponsorshipEditIdSelector);
  const sponsorshipSet = useSelector(basicAdSponsorshipSetCreateSelector);
  const sponsorship = useSelector(basicAdSponsorshipCreateSelector);
  const stickyMenuItem = useSelector(stickyMenuItemSelector);
  const adSets = useSelector(basicAdAdSetsSelector);
  const summaryAdSets = useSelector(basicAdSummaryAdSetsSelector);
  const campaign = useSelector(basicAdCampaignSelector);
  const activeCampaign = useSelector(basicAdCreateSelector);
  const activeSponsorshipSet = useSelector(basicAdSponsorshipSetCreateSelector);
  const basicAdfetching = useSelector(basicAdCampaignDataFetchingSelector);
  const preUploadLoading = useSelector(basicAdUpdateSponsorshipSelector);
  const unpublishedChanges = useSelector(basicAdUnpublishedChangesSelector);
  const currentCampaign = useMemo(() => {
    return {
      campaignEditId,
      sponsorshipSetId,
      sponsorshipEditId,
    };
  }, [campaignEditId, sponsorshipSetId, sponsorshipEditId]);

  const [width, setWidth] = useState(window.innerWidth);

  // Checking if sponsorship set required fields are filled out
  // const sponsorshipSetRequiredFields = useMemo(
  //   () =>
  //     sponsorshipSet.isUnite
  //       ? sponsorshipSet.startDate
  //       : sponsorshipSet.budget &&
  //         sponsorshipSet.startDate &&
  //         Object.keys(sponsorshipSet.selectedCharity) !== 0,
  //   [
  //     sponsorshipSet.budget,
  //     sponsorshipSet.startDate,
  //     sponsorshipSet.selectedCharity,
  //     sponsorshipSet.isUnite,
  //   ]
  // );

  // Checking if sponsorship required fields are filled out
  // const sponsorshipRequiredFields = useMemo(
  //   () =>
  //     sponsorshipSet.isUnite
  //       ? sponsorship.ctaCover &&
  //         sponsorship.adVideo &&
  //         sponsorship.adCover &&
  //         sponsorship.receivedQuestions
  //       : sponsorship.ctaCover && sponsorship.adVideo && sponsorship.receivedQuestions,
  //   [
  //     sponsorship.ctaCover,
  //     sponsorship.adVideo,
  //     sponsorshipSet.isUnite,
  //     sponsorship.adCover,
  //     sponsorship.receivedQuestions,
  //   ]
  // );

  const handleWindowWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth);

    return () => window.removeEventListener('resize', handleWindowWidth);
  }, [handleWindowWidth]);

  const campaignMode = useMemo(
    () => (!!currentCampaign.campaignEditId ? 'edit' : 'create'),
    [currentCampaign]
  );

  // const sponsorshipSetMode = useMemo(() => {
  //   if (campaignMode === 'edit' && !currentCampaign.sponsorshipSetId) {
  //     return 'create';
  //   }

  //   if (campaignMode === 'edit' && !!currentCampaign.sponsorshipSetId) {
  //     return 'edit';
  //   }

  //   return 'disabled';
  // }, [currentCampaign, campaignMode]);

  // const sponsorshipMode = useMemo(() => {
  //   if (
  //     sponsorshipSetMode === 'edit' &&
  //     !currentCampaign.sponsorshipEditId &&
  //     sponsorshipSetRequiredFields
  //   ) {
  //     return 'create';
  //   }

  //   if (sponsorshipSetMode === 'edit' && !!currentCampaign.sponsorshipEditId) {
  //     return 'edit';
  //   }

  //   return 'disabled';
  // }, [currentCampaign, sponsorshipSetMode, sponsorshipSetRequiredFields]);

  // const summaryMode = useMemo(() => {
  //   if (
  //     sponsorshipMode === 'edit' &&
  //     !!currentCampaign.sponsorshipEditId &&
  //     sponsorshipRequiredFields
  //   ) {
  //     return 'edit';
  //   }

  //   return 'disabled';
  // }, [currentCampaign, sponsorshipMode, sponsorshipRequiredFields]);

  const isDataFetching = basicAdfetching;
  return (
    <>
      <StickyMenu>
        {isDataFetching || preUploadLoading ? (
          <MenuPlaceholder />
        ) : (
          <>
            <Title>{t('Campaign Setup')}</Title>
            <BasicCampaignMenuSection
              mode={campaignMode}
              campaignId={campaign.id}
              stickyMenuItem={stickyMenuItem}
              activeCampaign={activeCampaign}
              adSets={adSets}
              isArchived={campaign.isArchive}
            />
            {adSets.map((adSet, index) => (
              <BasicAdSetMenuSection
                adSet={adSet}
                campaignId={campaign.id}
                key={index}
                index={index}
                activeSponsorshipSet={activeSponsorshipSet}
                sponsorship={sponsorship}
                sponsorshipEditId={currentCampaign?.sponsorshipEditId}
                sponsorshipSetId={currentCampaign?.sponsorshipSetId}
                sponsorshipSet={sponsorshipSet}
                stickyMenuItem={stickyMenuItem}
                isArchived={campaign.isArchive}
                unitIsApproved={adSet.deliveryStatus.toLowerCase() === 'approved' || !adSet.isDraft}
              />
            ))}
            <BasicSummaryMenu
              campaignId={campaign.id}
              isEdit={!campaign.isDraft}
              summaryAdSets={summaryAdSets}
              unpublishedChanges={unpublishedChanges}
            />
          </>
        )}
      </StickyMenu>

      {width <= 768 && (
        <MobileMenu>
          <BasicCampaignMenuSection
            mode={campaignMode}
            campaignId={campaign.id}
            stickyMenuItem={stickyMenuItem}
            activeCampaign={activeCampaign}
            adSets={adSets}
            isArchived={campaign.isArchived}
          />
          {adSets.map((adSet, index) => (
            <BasicAdSetMenuSection
              adSet={adSet}
              campaignId={campaign.id}
              key={index}
              activeSponsorshipSet={activeSponsorshipSet}
              sponsorship={sponsorship}
              sponsorshipEditId={currentCampaign?.sponsorshipEditId}
              sponsorshipSetId={currentCampaign?.sponsorshipSetId}
              sponsorshipSet={sponsorshipSet}
              stickyMenuItem={stickyMenuItem}
              isArchived={campaign.isArchived}
            />
          ))}
          <BasicSummaryMenu
            campaignId={campaign.id}
            isEdit={!campaign.isDraft}
            summaryAdSets={summaryAdSets}
            unpublishedChanges={unpublishedChanges}
          />
        </MobileMenu>
      )}
    </>
  );
};

export default BasicCampaignMenu;
