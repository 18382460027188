import sponsorshipSetConstants from '../../constants/sponsorshipSet/sponsorshipSet-data';
import sponsorshipConstants from '../../constants/sponsorship/sponsorship-data';
import brandConstants from '../../constants/brand/brand-data';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { formatToBrandsTimezone } from '../date/dateHelpers';
import { capitalizeFirstLetter } from '../formatting';

export const setInputDateFormat = (countryCode, withoutTime) => {
  let timeFormat = !withoutTime ? ' hh:mm aa' : '';
  let dateFormat = countryCode === 'US' || countryCode === 'CA' ? 'MM/dd/yyy' : 'dd/MM/yyy';

  return dateFormat + timeFormat;
};

export const setBrandDateFormat = (date, countryCode, includeTime) => {
  let dateFormat = 'dd/MM/yyyy';

  if (date && !isNaN(Date.parse(date))) {
    if (includeTime) {
      dateFormat =
        countryCode === 'US' || countryCode === 'CA'
          ? 'MM/dd/yyyy hh:mm aa'
          : 'dd/MM/yyyy hh:mm aa';
    } else {
      dateFormat = countryCode === 'US' || countryCode === 'CA' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
    }

    return formatDateUTC(new Date(date).toISOString(), dateFormat);
  } else {
    return '-';
  }
};

export const getModifiedBillsData = (billsData, countryCode, utcString) => {
  return billsData.map((item) => {
    return {
      ...item,
      createdDate: item.createdDate
        ? setBrandDateFormat(formatToBrandsTimezone(item.createdDate, utcString), countryCode)
        : '',
      dueDate: item.dueDate
        ? setBrandDateFormat(formatToBrandsTimezone(item.dueDate, utcString), countryCode)
        : '',
      paymentDate: item.paymentDate
        ? setBrandDateFormat(formatToBrandsTimezone(item.paymentDate, utcString), countryCode)
        : '',
      brandBillDetails: !item.brandBillDetails.length
        ? []
        : item.brandBillDetails.map((el) => {
            return {
              ...el,
              fromDate: el.fromDate
                ? setBrandDateFormat(formatToBrandsTimezone(el.fromDate, utcString), countryCode)
                : '',
              toDate: el.toDate
                ? setBrandDateFormat(formatToBrandsTimezone(el.toDate, utcString), countryCode)
                : '',
            };
          }),
    };
  });
};

export const getUrlFragment = (infoType) => {
  const { profileInfoTypes, profileUrlFragments } = brandConstants;

  if (profileInfoTypes.BRAND_TYPE === infoType) {
    return profileUrlFragments.BRAND_TYPE;
  }

  if (profileInfoTypes.BILLING_EMAIL === infoType) {
    return profileUrlFragments.BILLING_EMAIL;
  }

  if (profileInfoTypes.LEGAL_NAME === infoType) {
    return profileUrlFragments.LEGAL_NAME;
  }

  if (profileInfoTypes.ADDRESS === infoType) {
    return profileUrlFragments.ADDRESS;
  }

  return '';
};

export const addAllToArray = (array, length, name) => {
  if (array.length === length - 1) {
    array.push('all_' + name);
  }
  return array;
};

export const processDifferences = (data) => {
  return data.map((diff) => {
    return {
      id: diff.id,
      differences: diff.differences.map((d) => {
        return {
          modifiedField: d.modifiedField,
          publishedValue: d.publishedValue,
        };
      }),
    };
  });
};

export const processReceivedText = (text) => text?.split('_').join(' ');

export const processMediaType = (type) => ({
  value: type,
  label: capitalizeFirstLetter(type),
});

export const processAge = (ageRanges) => {
  const modifiedAge = ageRanges.reduce((acc, current) => {
    if (current.ageFrom === 65) {
      return [...acc, `${current.ageFrom}`];
    }
    return [...acc, `${current.ageFrom} - ${current.ageTo}`];
  }, []);

  return addAllToArray(modifiedAge, sponsorshipSetConstants.defaultAgeData.length, 'age');
};

export const processGender = (genders) => {
  const { genderData } = sponsorshipSetConstants;
  if (genders.includes('any') || genders.length >= 3) {
    return genderData.map((gender) => {
      return gender.value;
    });
  } else {
    return genderData
      .filter((gender) => genders.includes(gender.value))
      .map((gender) => gender.value);
  }
};

export const processReceivedDate = (receivedDate) =>
  formatDateUTC(new Date(receivedDate).toISOString(), 'yyyy-MM-dd');

export const processOs = (deviceType) =>
  sponsorshipSetConstants.osData.find((os) => os.value.toLowerCase() === deviceType) ??
  sponsorshipSetConstants.defaultOsData;

export const processInitialInterests = (interests) =>
  interests
    .filter((interest) => interest.tagType === 'self_serve')
    .map((interest) => ({
      ...interest,
      label: interest.displayName,
      value: interest.description,
      description: interest.description,
    }));

export const processInterests = (interests) => {
  return interests.map((interest) => {
    return {
      label: interest.replaceAll('_', ' '),
      value: interest,
    };
  });
};

export const processPassions = (passions) => {
  return passions.map((passion) => {
    return {
      label: passion.replaceAll('_', ' '),
      value: passion,
    };
  });
};

export const processTags = (tags) => {
  return tags
    .map((tag) => {
      return {
        label: tag,
        value: tag.toLowerCase(),
        name: tag,
        displayName: tag,
      };
    })
    .filter((item) => Object.keys(item).length);
};

export const processUsualTags = (tags) =>
  tags.map((tag) => ({
    label: tag,
    value: tag.toLowerCase(),
    name: tag,
    description: tag,
    id: uuidv4(),
  }));

export const processBrandRole = (role) => role === 'we_are8' && 'WeAre8';

export const processLocations = (locations) =>
  locations.map((location) => ({
    label: location.display,
    value: location.value,
  }));

export const processCharity = (id, name, logo) => ({
  id: id || name || '',
  name: name || '',
  label: name || '',
  value: name || '',
  logoUri: logo || '',
});

export const processCharityCategory = (charities) => {
  const categories = Object.values(
    charities
      .map((charity) => ({
        ...charity,
        label: charity.name,
        value: charity.name,
      }))
      .reduce(
        (result, charity, index, array) => ({
          ...result,
          [charity['category']]: {
            label: charity['category'],
            value: charity['category'],
            data: array.filter((item) => charity.category === item.category),
          },
        }),
        {}
      )
  );

  return categories
    ?.filter((category) => category.label)
    .map((category) => ({
      ...category,
      label: processReceivedText(category.label),
    }));
};

export const processVodStatus = (vodStatus) => {
  if (vodStatus === 'unknown') {
    return '';
  } else {
    return vodStatus;
  }
};

export const processFilterAcceptanceResponse = (response) => {
  const mapper = {
    'Viewed in feed (unique users)': 'Viewed',
    'Completed (unique users)': 'Completed',
    'Accepted (unique users)': 'Accepted',
    'Click Through (unique users)': 'Clicked',
  };

  return response?.series?.map((item) => ({
    name: item.name && mapper[item.name],
    label: item.name && mapper[item.name],
    value: item.name && mapper[item.name].toLowerCase(),
  }));
};

export const formatAcceptanceOverTimeResponse = (response) => {
  let series = response.series;
  series = series.map((category) => ({
    ...category,
    data: category.data.sort((a, b) => new Date(a.name) - new Date(b.name)).map((item) => item.y),
  }));
  return { ...response, series };
};

export const processReportingQuestionsOptions = (question, data, questionType) => {
  if (questionType === 'multiple_choice') {
    return data?.map((option, index) => ({
      questionId: question.metadata.questionId,
      questionKind: questionType,
      label: option.name,
      name: option.name,
      value: option.name,
      response: index,
    }));
  }

  if (questionType === 'select_all_that_apply') {
    return data?.map((option, index) => ({
      questionId: question.metadata.questionId,
      questionKind: questionType,
      label: option.name,
      name: option.name,
      value: option.name,
      response: {
        key: `${index}`,
        value: option.name,
      },
    }));
  }

  if (questionType === 'yes_no') {
    return data?.map((option) => ({
      questionId: question.metadata.questionId,
      questionKind: questionType,
      label: option.name,
      name: option.name,
      value: option.name !== 'No',
      response: option.name !== 'No',
    }));
  }

  if (questionType === 'viewer_sentiment') {
    const mapper = {
      1: '0x1F620',
      2: '0x1F641',
      3: '0x1F610',
      4: '0x1F642',
      5: '0x1F601',
    };

    return data
      ?.map((option) => {
        //HACK TO HANDLE UNEXPECTED VALUES BIGGER THAN 5
        const optionValue = Number(option?.name) >= 5 ? '5' : option?.name;
        return {
          questionId: question.metadata.questionId,
          questionKind: questionType,

          label: optionValue && `${String.fromCodePoint(mapper[optionValue])}`,
          value: +optionValue,
          name: +optionValue,
          response: +optionValue,
        };
      })
      .sort((a, b) => a.name - b.name)
      .filter((option, pos, self) => self.findIndex((v) => v.name === option.name) === pos);
  }

  if (questionType === 'short_answer') {
    return data?.map((option) => ({
      questionId: question.metadata.questionId,
      questionKind: questionType,
      label: option?.name,
      value: option?.name,
      name: option?.name,
    }));
  }
};

export const processReportingQuestions = (questions) => {
  let tempQuestions = [];

  for (let key in questions) {
    if (key === 'multipleChoiceResponses') {
      const temp = questions['multipleChoiceResponses']?.map((question) => ({
        type: 'multiple_choice',
        id: question.metadata.questionId,
        label: question.metadata.questionText,
        value: question.metadata.questionText,
        name: question.metadata.questionText,
        options: processReportingQuestionsOptions(question, question?.data, 'multiple_choice'),
      }));

      tempQuestions = [...tempQuestions, ...temp];
    }

    if (key === 'selectAllThatApplyResponses') {
      const temp = questions['selectAllThatApplyResponses']?.map((question) => ({
        type: 'select_all_that_apply',
        id: question.metadata.questionId,
        label: question.metadata.questionText,
        value: question.metadata.questionText,
        name: question.metadata.questionText,
        options: processReportingQuestionsOptions(
          question,
          question?.data,
          'select_all_that_apply'
        ),
      }));

      tempQuestions = [...tempQuestions, ...temp];
    }

    if (key === 'yesNoResponses') {
      const temp = questions['yesNoResponses']?.map((question) => ({
        type: 'yes_no',
        id: question.metadata.questionId,
        label: question.metadata.questionText,
        value: question.metadata.questionText,
        name: question.metadata.questionText,
        options: processReportingQuestionsOptions(question, question?.data, 'yes_no'),
      }));

      tempQuestions = [...tempQuestions, ...temp];
    }

    if (key === 'viewerSentimentResponses') {
      const temp = questions['viewerSentimentResponses']?.map((question) => ({
        type: 'viewer_sentiment',
        id: question.metadata.questionId,
        label: question.metadata.questionText,
        value: question.metadata.questionText,
        name: question.metadata.questionText,
        options: processReportingQuestionsOptions(question, question?.data, 'viewer_sentiment'),
      }));

      tempQuestions = [...tempQuestions, ...temp];
    }

    if (key === 'shortAnswers') {
      const temp = questions['shortAnswers']?.map((question) => ({
        type: 'short_answer',
        id: question.metadata.questionId,
        label: question.metadata.questionText,
        value: question.metadata.questionText,
        name: question.metadata.questionText,
        options: processReportingQuestionsOptions(question, question?.data, 'short_answer'),
      }));

      tempQuestions = [...tempQuestions, ...temp];
    }
  }
  const resultOptions = tempQuestions.reduce((acc, question) => acc.concat(question.options), []);

  const resultQuestions = tempQuestions.map((question) => ({
    type: question.type,
    id: question.id ?? uuidv4(),
    label: question.label,
    value: question.value,
    name: question.name,
  }));

  return {
    resultQuestions,
    resultOptions,
  };
};

export const processQuestions = (questions, brandName) => {
  let result = [];

  for (let key in questions) {
    if (key === 'multipleChoiceQuestions') {
      const temp = questions['multipleChoiceQuestions']
        .map((question) => ({
          receivedTags: question?.optionTags || {},
          updateId: question.id ?? uuidv4(),
          id: question.id ?? uuidv4(),
          name: 'Question',
          saved: true,
          text: question.questionText.replace('{{brand}}', brandName),
          questionOrder: question.questionOrder,
          type: { label: 'Multiple Choice', name: 'multiplechoice', value: 'MultipleChoice' },
          options: Object.values(question.optionTexts).map((option) => ({
            id: option.id ?? uuidv4(),
            name: 'Option',
            text: option,
          })),
        }))
        ?.map((question) => ({
          ...question,
          responsesOptions: question.options?.map((option, index) => ({
            ...option,
            tags:
              question?.receivedTags[`${index}`]?.map((tag) => ({
                id: uuidv4(),
                label: tag,
                value: tag.toLowerCase(),
                name: tag,
                description: tag,
              })) || [],
            tagsOptions: [],
          })),
        }))
        ?.map((question) => ({
          ...question,
          tagsCount: question?.responsesOptions?.reduce(
            (acc, current) => acc + current?.tags?.length,
            0
          ),
        }));

      result = [...result, ...temp];
    }

    if (key === 'selectAllThatApplyQuestions') {
      const temp = questions['selectAllThatApplyQuestions']
        .map((question) => ({
          receivedTags: question?.optionTags || {},
          updateId: question.id ?? uuidv4(),
          id: question.id ?? uuidv4(),
          name: 'Question',
          saved: true,
          text: question.questionText.replace('{{brand}}', brandName),
          questionOrder: question.questionOrder,
          type: {
            label: 'Select all that Apply',
            name: 'selectallthatapply',
            value: 'SelectAllThatApply',
          },
          options: Object.values(question.optionTexts).map((option) => ({
            id: uuidv4(),
            name: 'Option',
            text: option,
          })),
        }))
        ?.map((question) => ({
          ...question,
          responsesOptions: question?.options?.map((option, index) => ({
            ...option,
            tags:
              question?.receivedTags[`${index}`]?.map((tag) => ({
                id: uuidv4(),
                label: tag,
                value: tag.toLowerCase(),
                name: tag,
                description: tag,
              })) || [],
            tagsOptions: [],
          })),
        }))
        ?.map((question) => ({
          ...question,
          tagsCount: question?.responsesOptions?.reduce(
            (acc, current) => acc + current?.tags?.length,
            0
          ),
        }));

      result = [...result, ...temp];
    }

    if (key === 'shortAnswerQuestions') {
      const temp = questions['shortAnswerQuestions'].map((question) => ({
        updateId: question.id ?? uuidv4(),
        id: question.id ?? uuidv4(),
        name: 'Question',
        saved: true,
        text: question.questionText.replace('{{brand}}', brandName),
        questionOrder: question.questionOrder,
        type: { label: 'Short Answer', name: 'shortanswer', value: 'ShortAnswer' },
        options: [],
        responsesOptions: [],
        tagsCount: 0,
      }));

      result = [...result, ...temp];
    }
    if (key === 'viewerSentimentQuestions') {
      const temp = questions['viewerSentimentQuestions']
        .map((question) => ({
          updateId: question.id ?? uuidv4(),
          id: question.id ?? uuidv4(),
          name: 'Question',
          saved: true,
          text: question.questionText.replace('{{brand}}', brandName),
          questionOrder: question.questionOrder,
          type: { label: 'Viewer Sentiment', name: 'viewersentiment', value: 'ViewerSentiment' },
          options: [],
          responsesOptions: sponsorshipConstants.viewerSentimentTagsOptions?.map(
            (constant, index) => ({
              ...constant,
              ...(question?.optionTags && question?.optionTags.hasOwnProperty(`${index + 1}`)
                ? {
                    tags: question?.optionTags[`${index + 1}`]?.map((tag) => ({
                      id: uuidv4(),
                      label: tag,
                      value: tag.toLowerCase(),
                      name: tag,
                      description: tag,
                    })),
                    tagsOptions: [],
                  }
                : { tags: [] }),
            })
          ),
        }))
        .map((question) => ({
          ...question,
          tagsCount: question?.responsesOptions?.reduce(
            (acc, current) => acc + current?.tags?.length,
            0
          ),
        }));

      result = [...result, ...temp];
    }

    if (key === 'yesNoQuestions') {
      const temp = questions['yesNoQuestions']
        .map((question) => ({
          updateId: question.id ?? uuidv4(),
          id: question.id ?? uuidv4(),
          name: 'Question',
          saved: true,
          text: question.questionText.replace('{{brand}}', brandName),
          questionOrder: question.questionOrder,
          type: { label: 'Yes/No', name: 'yesno', value: 'YesNo' },
          options: [],
          responsesOptions: [
            {
              id: uuidv4(),
              name: 'Yes',
              text: 'Yes',
              tags:
                question.hasOwnProperty('yesTags') && question['yesTags']?.length > 0
                  ? question['yesTags']?.map((tag) => ({
                      id: uuidv4(),
                      label: tag,
                      value: tag.toLowerCase(),
                      name: tag,
                      description: tag,
                    }))
                  : [],
              tagsOptions: [],
            },
            {
              id: uuidv4(),
              name: 'No',
              text: 'No',
              tags:
                question.hasOwnProperty('noTags') && question['noTags']?.length > 0
                  ? question['noTags']?.map((tag) => ({
                      id: uuidv4(),
                      label: tag,
                      value: tag.toLowerCase(),
                      name: tag,
                      description: tag,
                    }))
                  : [],
              tagsOptions: [],
            },
          ],
        }))
        ?.map((question) => ({
          ...question,
          tagsCount: question?.responsesOptions?.reduce(
            (acc, current) => acc + current?.tags?.length,
            0
          ),
        }));

      result = [...result, ...temp];
    }
  }

  return result
    .sort((a, b) => a.questionOrder - b.questionOrder)
    .map((question, index) => ({
      ...question,
      questionOrder: index + 1,
    }));
};

export const processQuestionsFromList = (questions) =>
  questions.map((question) => ({
    ...question,
    kind:
      question.kind === 'yes_no'
        ? question.kind
            .split('_')
            .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
            .join('/')
        : processReceivedText(question.kind),
  }));

export const processReportingGenderOptions = (incomingData) => {
  const { data } = incomingData;
  return data?.length > 0
    ? data?.map((item) => ({
        label: item.name === 'NonBinary' ? 'Non Binary' : item.name,
        value: item.name,
        name: item.name,
      }))
    : [];
};

export const processReportingGenders = (unitArray, data) => {
  let result = [];

  if (unitArray.length === 3 || unitArray.includes('any')) {
    result = [...data];
  } else {
    unitArray.length > 0 &&
      unitArray.forEach((unit) => {
        const targeted = data?.find((dataItem) => dataItem?.value.toLowerCase() === unit);
        result.push(targeted);
      });
  }

  return result;
};

export const formatDateUTC = (utcTime, dateFormat) => {
  // const dateWithOffset = addLocaleOffset(new Date(localTime));

  // if (timezone) return `${format(dateWithOffset, dateFormat)} ${timezone}`;
  return format(new Date(utcTime), dateFormat);
};

export const transformAcceptanceData = (data) => {
  const { series } = data;
  const nameMapper = {
    'Viewed in feed (unique users)': 'Viewed',
    'Completed (unique users)': 'Completed',
    'Accepted (unique users)': 'Accepted',
    'Click Through (unique users)': 'Clicked',
  };

  return {
    ...data,
    series:
      series &&
      series?.map(({ name, data }) => {
        let total = 0;

        return {
          name: nameMapper && nameMapper[name] ? nameMapper[name] : name,
          data: data
            ?.sort((a, b) => a.x - b.x)
            ?.map(({ x, y }) => {
              total += y;

              return {
                name: x,
                x: new Date(x),
                y: total,
              };
            }),
        };
      }),
  };
};

export const transformDemographicsData = (data, mapper) => {
  const series = data[0];

  return {
    ...series,
    data: series.data?.map(({ metadata, name, x, y }) => ({
      metadata,
      name: mapper && mapper[name] ? mapper[name] : name,
      x: mapper && mapper[y] ? mapper[y] : y,
      y: x,
    })),
  };
};

export const processReceivedResponses = (incomingResponse) => {
  return [
    ...incomingResponse.multipleChoiceResponses,
    ...incomingResponse.selectAllThatApplyResponses,
    ...incomingResponse.viewerSentimentResponses,
    ...incomingResponse.yesNoResponses,
  ].map((series) => transformResponseSeries(series));
};

export const transformResponseSeries = (series) => {
  const seriesName = series.name;
  let total = series.data?.reduce((total, curr) => total + curr.x, 0);
  return {
    ...series,
    data: series.data
      ?.map(({ metadata = {}, name, x }) => {
        const nameValue =
          Number(name) >= 5 && seriesName === 'Viewer Sentiment Responses' ? '5' : name;
        return {
          metadata: {
            ...metadata,
            percentage: `${(x / total) * 100}`,
          },
          name: nameValue,
          x: nameValue,
          y: x,
        };
      })
      .sort((a, b) => b?.name.localeCompare(a?.name))
      .reduce(
        ((map) => (r, a) => {
          map.set(
            a.name,
            map.get(a.name) || r[r.push({ name: a.name, metadata: a.metadata, x: a.x, y: 0 }) - 1]
          );
          map.get(a.name).y += a.y;
          return r;
        })(new Map()),
        []
      ),
  };
};

export const processReceivedShortAnswers = (incomingResponse) => {
  const { shortAnswers } = incomingResponse;

  return [...shortAnswers]
    .map((series) => transformSeries(series))
    .map((item) => ({
      ...item,
      name: 'ShortAnswer',
    }));
};

export const transformSeries = (series, countryCode) => {
  return {
    ...series,
    data: series.data
      ?.map(({ metadata = {}, name }) => {
        return {
          lat: Number(Number(metadata.city_lat).toFixed(6)),
          lon: Number(Number(metadata.city_long).toFixed(6)),
          name,
          docs_count: metadata.docs_count,
          percentage: metadata.percentage,
          country_code: metadata.country_code,
        };
      })
      .filter((item) => item.country_code === countryCode && item.lon !== 0 && item.lat !== 0),
  };
};

export const processAnalyticsDemographics = (response) => {
  return [
    'influencerByAgeGrouped',
    'influencersByCountryCode',
    'influencersByRegionCode',
    'influencersByCityName',
    'influencersByAge',
    'influencersByGender',
    'influencersByPlatform',
    'influencersByRegionName',
  ].reduce(
    (data, key) => {
      let mapper = null;

      if (key === 'influencersByPlatform') {
        mapper = {
          android_phone: 'Android',
          apple_phone: 'iOS',
          ios_phone: 'iOS',
          i_phone: 'iOS',
        };
      }

      if (key === 'influencersByGender') {
        mapper = {
          Prefernottosay: 'Prefer Not To Say',
          Nonbinary: 'Non Binary',
        };
      }
      return {
        ...data,
        [key]: transformDemographicsData(response[key], mapper),
      };
    },
    { ...response }
  );
};

export const processFilteringBodyParams = (incomingObject) => {
  const {
    campaignId,
    adSetId,
    adId,
    type,
    questionId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    activity,
    brandId,
    responseFilter,
  } = incomingObject;

  const additionalData = brandId ? { brandId } : {};

  return {
    campaignId,
    adSetId,
    adId,
    type,
    id: adId ?? adSetId ?? campaignId,
    ...(questionId?.length > 0 && { questionId: questionId }),
    ...(gender?.value?.length > 0 && {
      gender: gender.value.replace(/ /g, '_'),
    }),
    ...(platform?.value?.length > 0 && { platform: platform.value }),
    ...(city?.value?.length > 0 && { city: city.value }),
    ...(fromAge?.value && { fromAge: `${fromAge?.value}` }),
    ...(toAge?.value && { toAge: `${toAge?.value}` }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
    ...(activity?.value?.length > 0 && { activity: activity?.value }),
    ...(responseFilter?.questionId?.length > 0 && {
      responseFilter: {
        questionId: responseFilter?.questionId,
        questionKind: responseFilter?.questionKind,
        response: responseFilter?.response,
      },
    }),
    ...additionalData,
  };
};

export const createNewTagsMapper = (tagsData) =>
  tagsData?.length > 0
    ? tagsData
        .map((tag) => tag.label)
        .reduce(
          (acc, current) => ({
            ...acc,
            [current.toLowerCase()]: current.toLowerCase(),
          }),
          {}
        )
    : undefined;

export const formatRate = (data) => {
  let number;
  if (data) {
    number = (data * 100) % 1 === 0 ? data * 100 : (data * 100).toFixed(2);
    return number + '%';
  } else return '-';
};

export const formatFrequency = (data) => {
  return data ? Number(data).toFixed(2) : '-';
};
